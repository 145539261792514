import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
// Motion
import { AnimatePresence, motion, Variants } from "framer-motion";
// Assets & Styling
import "./Auth.scss";
import BackgoundImage from "../../images/jpg/bg.jpg";
import { LogoImage } from "../../images";
// Components
import { SignInputGroup } from "./InsideAuth/SignInputGroup";
import ErrorAlert, {
  ErrorAlertWrapper,
} from "../../components/Form/ErrorAlert";
import { InProgress } from "../../components/Loaders";
// Type & Utils
import { AuthFormData } from "./types";
import { Errors } from "../../components/types";
import {
  authFormSignInValidate,
  authFormSignUpValidate,
  defaultAuthFormData,
} from "./utils";
import RouteLink from "../../components/utils/RouteLink";
// Redux Store
import { RootState } from "../../store/reducers";
import { signIn, SignUp } from "../../store/actions/auth.actions";
import setRouteStatus from "../utils/setRouteStatus";

const variants: Variants = {
  initial: {
    opacity: 0,
    y: -20,
    height: 0,
    marginBottom: "0px",
  },
  initialNoMargin: {
    opacity: 0,
    y: -20,
    height: 0,
    marginTop: "0px",
  },
  animate: {
    opacity: 1,
    y: 0,
    // transition: { delay: 1 },
    height: "auto",
    marginBottom: "20px",
  },
  animateNoMargin: {
    opacity: 1,
    y: 0,
    // transition: { delay: 1 },
    height: "auto",
    marginTop: "15px",
  },
};

type Props = {
  routeIsSignIn: boolean;
};

const Auth = ({ routeIsSignIn }: Props) => {
  const [isSignIn, setIsSignIn] = useState(routeIsSignIn);
  const [authFormData, setAuthFormData] = useState<AuthFormData>(
    defaultAuthFormData
  );
  const [formErrors, setFormErrors] = useState<Errors>([]);

  const [errorTriggerCount, setErrorTriggerCount] = useState(0); // I'm using the Error Trigger Count to Allow the Validation Error Popup to be shown again when triggered even if it has been triggered before
  const authStatus = useSelector((state: RootState) => state.auth.auth_status);
  const authError = useSelector((state: RootState) => state.auth.error_message);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.history.replaceState(
      null,
      `${isSignIn ? "Sign in" : "Sign up"} - Watch TV`,
      `/${isSignIn ? "SignIn" : "SignUp"}`
    );
  }, [isSignIn]);

  useEffect(() => {
    if (authStatus === "FAILED") {
      setFormErrors([{ message: String(authError?.message), show: true }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  // Form Input Value change
  //#region
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setAuthFormData({ ...authFormData, [e.target.name]: e.target.checked });
    } else {
      setAuthFormData({ ...authFormData, [e.target.name]: e.target.value });
    }
  };
  //#endregion

  // Form Submit Handler
  //#region
  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();

    var formData = new FormData();

    // If Signing In
    if (isSignIn) {
      let err: Errors = authFormSignInValidate(authFormData);

      if (err.length > 0) {
        setErrorTriggerCount(errorTriggerCount + 1);
        setFormErrors([...err]);
        return;
      } else {
        setFormErrors([]);
        formData.append("email", authFormData.email);
        formData.append("password", authFormData.password);
        // TODO: HANDLE REMEMBER ME
        dispatch(signIn(formData, history));
      }
    }
    // if signing up
    else {
      let err: Errors = authFormSignUpValidate(authFormData);

      if (err.length > 0) {
        setErrorTriggerCount(errorTriggerCount + 1);
        setFormErrors([...err]);
        return;
      } else {
        setFormErrors([]);
        formData.append("firstName", authFormData.firstName as string);
        formData.append("lastName", authFormData.lastName as string);
        formData.append("email", authFormData.email);
        formData.append("password", authFormData.password);
        formData.append(
          "confirmPassword",
          authFormData.confirmPassword as string
        );
        formData.append(
          "privacyPolicyAgreed",
          String(authFormData.privacyPolicyAgreed)
        );

        dispatch(SignUp(formData, history));
      }
    }
  };
  //#endregion

  const handleDemoSubmit = (signInDetails: AuthFormData) => {
    var formData = new FormData();

    // If Signing In
    if (isSignIn) {
      let err: Errors = authFormSignInValidate(signInDetails);

      if (err.length > 0) {
        setErrorTriggerCount(errorTriggerCount + 1);
        setFormErrors([...err]);
        return;
      } else {
        setFormErrors([]);
        formData.append("email", signInDetails.email);
        formData.append("password", signInDetails.password);
        // TODO: HANDLE REMEMBER ME
        dispatch(signIn(formData, history));
      }
    }
  };

  const switchMode = () => {
    setAuthFormData({
      ...authFormData,
      privacyPolicyAgreed: false,
      remember: false,
    });
    setIsSignIn((currentIsSignIn) => !currentIsSignIn);
  };
  const handleForgotPassword = () => {};

  return (
    <>
      <Helmet>
        <title>{isSignIn ? "Sign in" : "Sign up"} - Watch TV</title>
        <script type="text/javascript" src="/assets/js/main.js"></script>
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="sign section--full-bg"
        // data-bg={BackgoundImage}
      >
        <BG
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.5 } }}
          src={BackgoundImage}
          alt="Background"
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sign__content">
                {/* <!-- authorization form --> */}

                <motion.form
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.7, duration: 0.5 }}
                  className="sign__form"
                >
                  <RouteLink to="/" className="sign__logo">
                    <motion.img
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 1 }}
                      src={LogoImage}
                      alt=""
                    />
                  </RouteLink>

                  {/* TODO ::: Error Alert Wrapper */}
                  <ErrorAlertWrapper>
                    {formErrors.map((error, index) => (
                      <ErrorAlert
                        key={`${index} ${errorTriggerCount}`}
                        index={index}
                        errors={formErrors}
                        setErrors={(errors) => setFormErrors([...errors])}
                      />
                    ))}
                  </ErrorAlertWrapper>

                  <AnimatePresence>
                    {!isSignIn && (
                      //   Name
                      <motion.div
                        variants={variants}
                        exit="initial"
                        initial="initial"
                        animate="animate"
                        className="sign__group"
                      >
                        <SignInputGroup>
                          {/* First Name */}
                          <input
                            name="firstName"
                            value={authFormData.firstName}
                            onChange={handleChange}
                            type="text"
                            className="sign__input"
                            placeholder="First Name"
                            required
                          />

                          {/* Last Name */}
                          <input
                            name="lastName"
                            value={authFormData.lastName}
                            onChange={handleChange}
                            type="text"
                            className="sign__input"
                            placeholder="Last Name"
                            required
                          />
                        </SignInputGroup>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  {/* Email */}
                  <div className="sign__group">
                    <input
                      name="email"
                      value={authFormData.email}
                      onChange={handleChange}
                      type="text"
                      className="sign__input"
                      placeholder="Email"
                      required
                    />
                  </div>

                  {/* Password */}
                  <div className="sign__group">
                    <input
                      name="password"
                      value={authFormData.password}
                      onChange={handleChange}
                      type="password"
                      className="sign__input"
                      placeholder="Password"
                      required
                    />
                  </div>

                  <AnimatePresence>
                    {/* Confirm Password */}
                    {!isSignIn && (
                      <motion.div
                        variants={variants}
                        exit="initial"
                        initial="initial"
                        animate="animate"
                        className="sign__group"
                      >
                        <input
                          name="confirmPassword"
                          value={authFormData.confirmPassword}
                          onChange={handleChange}
                          type="password"
                          className="sign__input"
                          placeholder="Confirm Password"
                          required
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <div className="sign__group sign__group--checkbox">
                    {isSignIn ? (
                      <>
                        <input
                          id="remember"
                          name="remember"
                          type="checkbox"
                          defaultChecked={false}
                          onChange={handleChange}
                        />
                        <label htmlFor="remember">Remember me</label>
                      </>
                    ) : (
                      <>
                        <input
                          id="privacyPolicyAgreed"
                          name="privacyPolicyAgreed"
                          type="checkbox"
                          defaultChecked={false}
                          onChange={handleChange}
                        />
                        <label htmlFor="privacyPolicyAgreed">
                          I agree to the{" "}
                          <RouteLink to="/AboutUs">Privacy policy</RouteLink>
                        </label>
                      </>
                    )}
                  </div>

                  <button
                    className="sign__btn"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {isSignIn ? "Sign in" : "Sign Up"}
                    {authStatus === "IN PROGRESS" && (
                      <motion.span
                        initial={{ width: 0 }}
                        animate={{ width: "auto" }}
                      >
                        <InProgress size={2} />
                      </motion.span>
                    )}
                  </button>

                  <span className="sign__delimiter">or</span>

                  {/* <div className="sign__social">
                    <span className="fb">
                      <FacebookIcon />
                    </span>
                    <span className="tw">
                      <TwitterIcon />
                    </span>
                    <span className="gl">
                      <GoogleIcon />
                    </span>
                  </div> */}

                  <span className="sign__text">
                    {isSignIn
                      ? "Don't have an account? "
                      : "Already have an account? "}
                    <span className="link" onClick={switchMode}>
                      {isSignIn ? "Sign up!" : "Sign in!"}
                    </span>
                  </span>

                  <AnimatePresence>
                    {isSignIn && (
                      <motion.span
                        variants={variants}
                        exit="initialNoMargin"
                        initial="initialNoMargin"
                        animate="animateNoMargin"
                        className="sign__text"
                      >
                        <span className="link" onClick={handleForgotPassword}>
                          Forgot password?
                        </span>
                      </motion.span>
                    )}
                  </AnimatePresence>

                  <button
                    className="sign__btn demo__sign__btn"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isSignIn) setIsSignIn(true);
                      const signInDetails = {
                        email: "demouser@jesuseyitan.com",
                        password: "demouser123",
                        remember: true,
                      };
                      setAuthFormData({
                        ...authFormData,
                        ...signInDetails,
                      });
                      handleDemoSubmit({ ...authFormData, ...signInDetails });
                    }}
                  >
                    {"DEMO Sign In"}
                  </button>
                </motion.form>
                {/* <!-- end authorization form --> */}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      {/* <!-- end sign in --> */}
    </>
  );
};

export default Auth;

const BG = styled(motion.img)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(1rem);
`;
