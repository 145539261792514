import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import setRouteStatus from "../../pages/utils/setRouteStatus";

const RouteLink: typeof Link = ({ to, children, ...props }) => {
  const dispatch = useDispatch();
  return (
    <Link
      onClick={() => dispatch(setRouteStatus("STARTED"))}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
};

export default RouteLink;
