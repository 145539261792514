import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import Layout from "../../components/Layout/Layout";
import SubscriptionPlansSection from "../Home/InsideHome/SubscriptionPlansSection";
import { variantsPage, variantsPageSection } from "../utils/motionVariants";
import setRouteStatus from "../utils/setRouteStatus";
import PricingHeaderSection from "./InsidePricing/PricingHeaderSection";

const Pricing = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <motion.div
        variants={variantsPage}
        initial="initial"
        animate="animate"
        exit="initial"
      >
        {helmet}
        <motion.div variants={variantsPageSection} key="Header">
          <PricingHeaderSection />
        </motion.div>

        <motion.div
          variants={variantsPageSection}
          key="Subscription Plan Section"
        >
          <SubscriptionPlansSection />
        </motion.div>
      </motion.div>
    </Layout>
  );
};

export default Pricing;

const helmet = (
  <Helmet>
    <title>Pricing - Watch TV</title>
    <script type="text/javascript" src="/assets/js/main.js"></script>
  </Helmet>
);
