import { SET_ROUTE_STATUS } from "./actionTypes";
import { RouteActionTypes, RouteState } from "./types";

const INITIAL_STATE: RouteState = {
  status: "IDLE",
};

const reducer = (
  state: RouteState = INITIAL_STATE,
  action: RouteActionTypes
): RouteState => {
  switch (action.type) {
    case SET_ROUTE_STATUS:
      return { ...state, status: action.payload };

    default:
      return state;
  }
};

export default reducer;
