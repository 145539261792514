import axios from "axios";
import { localStorageUserKey } from "../constants/localstorage";

// SETUP
const BASE_URL = "https://watch-tv-project.herokuapp.com/api/";
const API = axios.create({ baseURL: BASE_URL });

// Interceptor to send token to be verified
API.interceptors.request.use((req) => {
  if (localStorage.getItem(localStorageUserKey)) {
    req.headers.authorization = `Bearer ${
      JSON.parse(String(localStorage.getItem(localStorageUserKey))).token
    }`;
  }
  return req;
});

// USER AUTH
//#region
export const signUp = (auth_formdata: FormData) =>
  API.post("/user/authentication/signup", auth_formdata);
export const signIn = (auth_formdata: FormData) =>
  API.post("/user/authentication/signin", auth_formdata);
//#endregion

// MOVIES
//#region
export const getMovies = () => API.get("/movies");
export const getWatchList = () => API.get("/movies/watchList");
export const updateWatchList = (movieId: string) =>
  API.patch(`/movies/watchList/${movieId}`);
//#endregion
