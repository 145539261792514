import { Settings } from "react-slick";

interface MySettings extends Settings {
  center?: boolean;
}
export const homeSliderSettings: MySettings = {
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 600,
  centerMode: true,
  center: true,
  draggable: true,
  swipeToSlide: true,
  adaptiveHeight: true,
  variableWidth: true,

  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        dots: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        dots: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // adaptiveHeight: false,
      },
    },
    {
      breakpoint: 0,
      settings: {
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const movieSliderSettings: Settings = {
  //   dots: true,
  autoplay: true,
  infinite: true,
  speed: 600,
  centerMode: true,
  draggable: true,
  swipeToSlide: true,
  adaptiveHeight: true,
  variableWidth: true,
  arrows: false,

  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 6,
        dots: false,
        // slidesToScroll: 6,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        dots: false,
        arrows: true,
        // slidesToScroll: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 3,
        // slidesToScroll: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        // center: true,
        arrows: true,
        centerMode: true,
        slidesToShow: 1,
        // slidesToScroll: 1,
        // adaptiveHeight: false,
      },
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        // slidesToScroll: 1,
      },
    },
  ],
};
export const originalsMovieSliderSettings: MySettings = {
  //   dots: true,
  autoplay: true,
  infinite: true,
  speed: 600,
  center: true,
  centerMode: true,
  draggable: true,
  swipeToSlide: true,
  adaptiveHeight: true,
  variableWidth: true,
  arrows: true,

  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        dots: false,
        // slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        dots: false,
        // slidesToScroll: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        dots: false,
        // slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        // slidesToScroll: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        // center: true,
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        // adaptiveHeight: false,
        arrows: true,
      },
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// var num = add(1,2)
