import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Errors } from "../types";

type Props = {
  index: number;
  errors: Errors;
  setErrors: (errors: Errors) => void;
};
const ErrorAlert = ({ index, errors, setErrors }: Props) => {
  const [hidden, setHidden] = useState(!errors[index].show);

  useEffect(() => {
    setTimeout(() => setHidden(!errors[index].show), 300);
  }, [errors, index]);

  const handleClose = () => {
    let tempErrors = errors;
    tempErrors[index].show = false;
    setErrors(tempErrors);
  };

  useEffect(() => {
    if (!hidden) {
      setTimeout(() => handleClose(), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertMessage show={errors[index].show} hide={hidden}>
      <span>Error: {errors[index].message} </span>
      <AlertRemove onClick={handleClose} />
    </AlertMessage>
  );
};

export default ErrorAlert;

const AlertMessage = styled("div")<{ show: boolean; hide: boolean }>`
  animation: ${(props) => (props.show ? "FadeIn 300ms" : "FadeOut 500ms")};
  animation-fill-mode: forwards;

  background-color: #131720e2;
  border: 1px solid #eb5757;
  color: #eb5757;

  /* min-width: 300px; */
  padding: 10px 20px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
  border-radius: 16px;

  cursor: default;

  ${(props) =>
    props.hide &&
    `visibility: none;
     display: none;`}

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }

  @keyframes FadeOut {
    0% {
      opacity: 100;
    }
    100% {
      opacity: 0;
      /* margin-top: -56px; */
    }
  }
`;

const AlertRemove = styled("div")`
  &::before {
    content: "×";
  }

  color: #eb5757;
  margin-right: 7px;
  transition: color 0.5s;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;

  &:hover {
    color: #fff;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 770px) {
    position: sticky;
    z-index: 1052;
    top: 5em;
    width: 95%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1em;
  min-width: 300px;
  position: absolute;
  z-index: 1052;
`;

export const ErrorAlertWrapper: FC = ({ children }) => {
  return (
    <Wrapper className="col-12">
      <Container>{children}</Container>
    </Wrapper>
  );
};
