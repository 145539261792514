import { motion } from "framer-motion";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import Layout from "../../components/Layout/Layout";
import { variantsPage, variantsPageSection } from "../utils/motionVariants";
import setRouteStatus from "../utils/setRouteStatus";

const About = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <motion.div
        variants={variantsPage}
        initial="initial"
        animate="animate"
        exit="initial"
      >
        <Helmet>
          <title>Watch TV</title>
        </Helmet>

        <motion.div variants={variantsPageSection} key="Header">
          <AboutHeaderSection />
        </motion.div>

        <motion.div variants={variantsPageSection} key="Body">
          <AboutBodySection />
        </motion.div>
      </motion.div>
    </Layout>
  );
};

export default About;
const AboutHeaderSection = () => {
  return (
    <section className="section section--head section--head-fixed">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6">
            <h1 className="section__title section__title--head">Watch Tv</h1>
          </div>

          <div className="col-12 col-xl-6">
            <ul className="breadcrumb">
              <li className="breadcrumb__item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb__item breadcrumb__item--active">
                About
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export const AboutBodySection = () => {
  return (
    <section className="section section--pb0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="section__text section__text--small">
              At Watch TV, we want to entertain the world. Whatever your taste,
              and no matter where you live, we give you access to best-in-class
              TV shows, movies and documentaries. Our members control what they
              want to watch, when they want it, with no ads, in one simple
              subscription. We’re streaming in more than 30 languages and 190
              countries, because great stories can come from anywhere and be
              loved everywhere. We are the world’s biggest fans of
              entertainment, and we’re always looking to help you find your next
              favorite story.
            </p>

            <p className="section__text section__text--small">
              At Watch TV, we want to entertain the world. Whatever your taste,
              and no matter where you live, we give you access to best-in-class
              TV shows, movies and documentaries. Our members control what they
              want to watch, when they want it, with no ads, in one simple
              subscription. We’re streaming in more than 30 languages and 190
              countries, because great stories can come from anywhere and be
              loved everywhere. We are the world’s biggest fans of
              entertainment, and we’re always looking to help you find your next
              favorite story.
            </p>
            <p className="section__text section__text--small">
              At Watch TV, we want to entertain the world. Whatever your taste,
              and no matter where you live, we give you access to best-in-class
              TV shows, movies and documentaries. Our members control what they
              want to watch, when they want it, with no ads, in one simple
              subscription. We’re streaming in more than 30 languages and 190
              countries, because great stories can come from anywhere and be
              loved everywhere. We are the world’s biggest fans of
              entertainment, and we’re always looking to help you find your next
              favorite story.
            </p>
          </div>
        </div>

        <div className="row row--grid">
          <div className="col-12 col-lg-4">
            <div className="step">
              <span className="step__number">01</span>
              <h3 className="step__title">Choose your Plan</h3>
              <p className="step__text">
                I'm just typing anything here so i don't get caught up looking
                for content and instead keep to the actual coding
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="step">
              <span className="step__number">02</span>
              <h3 className="step__title">Create an account</h3>
              <p className="step__text">
                I'm just typing anything here so i don't get caught up looking
                for content and instead keep to the actual coding
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="step">
              <span className="step__number">03</span>
              <h3 className="step__title">Enjoy WatchTV</h3>
              <p className="step__text">
                I'm just typing anything here so i don't get caught up looking
                for content and instead keep to the actual coding
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
