import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImagePreloader from "../../../../components/Loaders/PreLoaders/ImagePreloader";
import RouteLink from "../../../../components/utils/RouteLink";
import { PlayIcon, RatingsIcon, SaveIcon } from "../../../../images";
import { updateWatchList } from "../../../../store/actions/movies.actions";
import { RootState } from "../../../../store/reducers";
import { IMovie } from "../../../../store/reducers/movies/types";
import { variantsListContent } from "../../../utils/motionVariants";

type Props = {
  movie: IMovie;
  index: number;
  watchList: string[];
};
const CatalogMovieCard = ({ movie, index, watchList }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );
  const dispatch = useDispatch();

  return (
    <motion.div
      variants={variantsListContent}
      layoutId={movie.slug}
      key={movie.slug}
      className="col-6 col-sm-4 col-lg-3 col-xl-2"
    >
      <div className="card">
        <RouteLink to={`/Movie/v/${movie._id}`} className="card__cover">
          {!loaded && <ImagePreloader useHeight width={192} height={270} />}
          <img
            src={movie.cover_image}
            alt={movie.title}
            onLoad={() => setLoaded(true)}
            style={loaded ? { display: "inherit" } : { display: "none" }}
            width={192}
          />
          <PlayIcon />
        </RouteLink>

        {isAuthenticated && (
          <button
            className={`card__add ${watchList.includes(movie._id) && "save"}`}
            type="button"
            onClick={() => {
              dispatch(updateWatchList(movie._id));
            }}
          >
            <SaveIcon />
          </button>
        )}

        <span className="card__rating">
          <RatingsIcon />
          {movie.rating}
        </span>
        <h3 className="card__title">
          <RouteLink to={`/Movie/v/${movie._id}`}>{movie.title}</RouteLink>
        </h3>
        <ul className="card__list">
          <li>{movie.genres[0]?.trim()}</li>
          <li>{movie.genres[1]?.trim()}</li>
          <li>{new Date(movie.release_date).getFullYear()}</li>
        </ul>
      </div>
    </motion.div>
  );
};

export default CatalogMovieCard;
