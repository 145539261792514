import React from "react";
import { LogInIcon, ProfileIcon } from "../../../images";
import RouteLink from "../../utils/RouteLink";

type Name = {
  first_name: string;
  last_name: string;
};
type Props = {
  isAuthenticated: boolean;
  userName: Name;
};

const HeaderUser = ({ isAuthenticated, userName }: Props) => {
  return (
    <>
      {isAuthenticated && userName ? (
        <RouteLink to="/Profile" className="header__user">
          <span>{`${
            userName.first_name.length < 7
              ? userName.first_name
              : userName.first_name[0]
          } ${userName.last_name[0]}`}</span>
          <ProfileIcon />
        </RouteLink>
      ) : (
        <RouteLink to="/SignIn" className="header__user">
          <span>Sign in</span>
          <LogInIcon />
        </RouteLink>
      )}
    </>
  );
};

export default HeaderUser;
