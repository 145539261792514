import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import Layout from "../../components/Layout/Layout";
import WatchListSection from "../Home/InsideHome/WatchListSection";
import { variantsPage, variantsPageSection } from "../utils/motionVariants";
import setRouteStatus from "../utils/setRouteStatus";
import ProfileHeaderSection from "./InsideProfile/ProfileHeaderSection";
import ProfileNav from "./InsideProfile/ProfileNav";
import ProfileTab from "./InsideProfile/ProfileTab";

const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <motion.div
        variants={variantsPage}
        initial="initial"
        animate="animate"
        exit="initial"
      >
        {helmet}

        <ProfileHeaderSection />

        <div className="profile--page catalog catalog--page">
          <ProfileNav />

          <motion.div
            variants={variantsPageSection}
            key="Profile tab Section"
            className="container"
          >
            <div className="tab-content">
              <Switch>
                <Route exact path="/Profile">
                  <ProfileTab />
                </Route>
                <Route exact path={"/Profile/Favorites"}>
                  <motion.section
                    variants={variantsPageSection}
                    key="Watch List Movies"
                    className="section  section--gradient"
                  >
                    <WatchListSection />
                  </motion.section>
                </Route>
                <Route exact path={"/Profile/Settings"}>
                  <h1>Settings: To Be Added</h1>
                </Route>
                <Route render={() => <Redirect to="/Profile" />} />
              </Switch>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default Profile;

const helmet = (
  <Helmet>
    <title>My Profile - Watch TV</title>
    <script type="text/javascript" src="/assets/js/main.js"></script>
  </Helmet>
);
