import Slider from "react-slick";
import styled from "styled-components";

const CarouselSlider = styled(Slider)`
  & .slick-slide {
    /* margin: auto; */
    & .movie__card__slide,
    & .originals__card__slide {
      @media (min-width: 576px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      @media (min-width: 768px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      @media (min-width: 1200px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  & .slick-arrow {
    height: 100%;
    width: 150px;
    opacity: 0.6;
    transition: 0.5s ease !important;
    &:before {
      font-size: 40px;
      color: #2f81ed;
    }
    &:hover {
      opacity: 1;
      :before {
        opacity: 0.6;
      }
    }
    &.slick-prev {
      z-index: 1 !important;
      background-image: linear-gradient(90deg, #131720, transparent);
      &:hover {
        background-image: linear-gradient(90deg, #131720, transparent);
      }
    }
    &.slick-next {
      background-image: linear-gradient(90deg, transparent, #131720);
      &:hover {
        background-image: linear-gradient(90deg, transparent, #131720);
      }
    }
    @media (max-width: 576px) {
      width: 100px;
      &:before {
        font-size: 30px;
      }
    }
  }
`;

export default CarouselSlider;
