import { ErrorAlertType, Errors } from "../../components/types";
import { AuthFormData } from "./types";

export const defaultAuthFormData: AuthFormData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  remember: false,
  privacyPolicyAgreed: false,
};

//#region ::: SIGN UP VALIDATE
export const authFormSignUpValidate = (authFormData: AuthFormData) => {
  var err: Errors = [];

  //   Name
  const firstNameErr = nameValidate(
    "First Name",
    authFormData.firstName as string
  );
  const lastNameErr = nameValidate(
    "Last Name",
    authFormData.lastName as string
  );
  if (firstNameErr) err.push(firstNameErr);
  if (lastNameErr) err.push(lastNameErr);

  //   Email
  const emailErr = emailValidate(authFormData.email);
  if (emailErr) err.push(emailErr);

  //   Password
  const passwordErr = passwordValidate(authFormData.password);
  if (passwordErr) err.push(passwordErr);
  else {
    const passwordMatchErr = passwordMatchValidate(
      authFormData.password,
      authFormData.confirmPassword as string
    );
    if (passwordMatchErr) err.push(passwordMatchErr);
  }

  //   Privacy Policy
  if (!authFormData.privacyPolicyAgreed)
    err.push({
      message: "You need to agree to privacy policy to continue",
      show: true,
    });

  return err;
};
//#endregion

//#region ::: SIGN IN VALIDATE
export const authFormSignInValidate = (authFormData: AuthFormData) => {
  var err: Errors = [];

  //   Email
  const emailErr = emailValidate(authFormData.email);
  if (emailErr) err.push(emailErr);

  //   Password
  const passwordErr = passwordValidate(authFormData.password);
  if (passwordErr) err.push(passwordErr);

  return err;
};
//#endregion

const nameValidate = (
  fieldname: string,
  value: string
): ErrorAlertType | null => {
  if (value.trim() === "")
    return { message: `${fieldname} is required`, show: true };
  else if (/[^a-zA-Z -]/.test(value))
    return {
      message: `${fieldname} contains invalid characters`,
      show: true,
    };
  else if (value.trim().length < 3)
    return {
      message: `${fieldname} needs to be at least three characters`,
      show: true,
    };
  else return null;
};

const emailValidate = (email: string): ErrorAlertType | null => {
  if (email.trim() === "") return { message: "Email is required", show: true };
  else if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  )
    return null;
  else return { message: "Please enter a valid email", show: true };
};

const passwordValidate = (password: string): ErrorAlertType | null => {
  if (password === "") return { message: `Password is required`, show: true };
  else if (password.length < 8)
    return {
      message: `Password needs to be at least 8 characters`,
      show: true,
    };
  else return null;
};

const passwordMatchValidate = (
  password: string,
  confirmPassword: string
): ErrorAlertType | null => {
  if (password !== confirmPassword)
    return { message: "Passwords do not match", show: true };
  else return null;
};
