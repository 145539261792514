import React from "react";
import RouteLink from "../../../components/utils/RouteLink";

const MoviesHeaderSection = () => {
  return (
    <section className="section section--head section--head-fixed">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6">
            <h1 className="section__title section__title--head">Top Genres</h1>
          </div>

          <div className="col-12 col-xl-6">
            <ul className="breadcrumb">
              <li className="breadcrumb__item">
                <RouteLink to="/Home">Home</RouteLink>
              </li>
              <li className="breadcrumb__item breadcrumb__item--active">
                Movies
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MoviesHeaderSection;
