import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RouteNavLink from "../../../components/utils/RouteNavLink";
import { localStorageUserKey } from "../../../constants/localstorage";
import { Avatar, LogoutIcon } from "../../../images";
import { signOut } from "../../../store/actions/auth.actions";

const navLinks = [
  { name: "Profile", to: "/Profile" },
  { name: "Favorites", to: "/Profile/Favorites" },
  { name: "Settings", to: "/Profile/Settings" },
];

const ProfileNav = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userName = JSON.parse(
    localStorage.getItem(localStorageUserKey) as string
  )?.user.name;
  const handleSignOut = () => {
    dispatch(signOut(history));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="profile">
            {/* Profile User */}
            <div className="profile__user">
              <div className="profile__avatar">
                <img src={Avatar} alt="Profile Avatar" />
              </div>
              <div className="profile__meta">
                <h3>
                  {userName?.first_name ?? "Sign In"}{" "}
                  {userName?.last_name ?? ""}
                </h3>
                <span>Premium</span>
              </div>
            </div>

            <ul
              className="nav nav-tabs profile__tabs"
              id="profile__tabs"
              role="tablist"
            >
              {navLinks.map((navLink) => (
                <li key={navLink.name} className="nav-item">
                  <RouteNavLink
                    to={navLink.to}
                    exact
                    className="nav-link"
                    activeClassName="active"
                  >
                    {navLink.name}
                  </RouteNavLink>
                </li>
              ))}
            </ul>

            <button
              className="profile__logout"
              type="button"
              onClick={handleSignOut}
            >
              <span>Sign out</span>
              <LogoutIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNav;
