import {
  MovieIcon,
  NewCommentsIcon,
  PlanIcon,
  StarIcon,
} from "../../../images";

const ProfileTab = () => {
  return (
    <>
      <div className="tab-pane" id="tab-1" role="tabpanel">
        <div className="row row--grid">
          <div className="col-12 col-sm-6 col-xl-3">
            <div className="stats">
              <span>Premium plan</span>
              <p>{"£14.99 /month"}</p>
              <PlanIcon />
            </div>
          </div>

          <div className="col-12 col-sm-6 col-xl-3">
            <div className="stats">
              <span>Films watched</span>
              <p>{0}</p>
              <MovieIcon />
            </div>
          </div>

          <div className="col-12 col-sm-6 col-xl-3">
            <div className="stats">
              <span>Your comments</span>
              <p>{0}</p>
              <NewCommentsIcon />
            </div>
          </div>

          <div className="col-12 col-sm-6 col-xl-3">
            <div className="stats">
              <span>Your Reviews</span>
              <p>0</p>
              <StarIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTab;
