import { FormEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { RootState } from "../../store/reducers";
import WatchListSection from "../Home/InsideHome/WatchListSection";
import CatalogHeader from "./InsideMovieCategory/Catalog/CatalogHeader";
import CatalogMovieCard from "./InsideMovieCategory/Catalog/CatalogMovieCard";
import CategoryHeaderSection from "./InsideMovieCategory/CategoryHeaderSection";
import { genres, getFilteredMovies, sortMovies } from "./utils";
import { Params } from "./type";
import { IMovie } from "../../store/reducers/movies/types";
import { AnimatePresence, motion } from "framer-motion";
import {
  variantsPage,
  variantsPageSection,
  variantsListContainer,
} from "../utils/motionVariants";
import setRouteStatus from "../utils/setRouteStatus";

const MoviesCategory = () => {
  //  * UseSelectors
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );
  const movies = useSelector((state: RootState) => state.movies.movies);
  const watchList = useSelector((state: RootState) =>
    state.movies.watchlist.map((watchListItem) => watchListItem.movieId)
  );

  // * UseState
  const [filteredMovies, setFilteredMovies] = useState<IMovie[]>([]);
  const [{ category, id, sort_option }, setParams] = useState(
    useParams<Params>()
  );
  const [sortOption, setSortOption] = useState(
    category === "All" && id ? id : sort_option ? sort_option : "Popular"
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getFilteredMovies(category, id, setFilteredMovies, setSortOption, movies);
  }, [movies, category, id]);

  useEffect(() => {
    if (category === "Genre" && sort_option)
      setFilteredMovies(sortMovies(filteredMovies, sort_option));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // * Handle Genre changing by user
  const onGenreChange = (value: string) => {
    if (value !== "All Genres") {
      setParams({ category: "Genre", id: value });
      window.history.replaceState(
        null,
        `${value} Movies`,
        `/Movies/Genre/${value}`
      );
    } else {
      let sortId = sortOption === "" ? "Newest" : sortOption;

      setParams({ category: "All", id: sortId });
      window.history.replaceState(null, "All Movies", `/Movies/All/${sortId}`);
    }
  };

  // * Handle sort Change
  const onSortChange = (event: FormEvent<HTMLDivElement>) => {
    let value = (event.target as HTMLInputElement).value;
    if (category === "Genre") {
      const baseURL = `/Movies/Genre/${id}`;
      setFilteredMovies(sortMovies(filteredMovies, value));
      setSortOption(value);
      window.history.replaceState(
        null,
        "New Page Title",
        `${baseURL}/${value}`
      );
    } else {
      setParams({ category: "All", id: value });
      window.history.replaceState(
        null,
        "New Page Title",
        `/Movies/All/${value}`
      );
    }
  };

  return (
    <>
      <Layout>
        <motion.div
          variants={variantsPage}
          initial="initial"
          animate="animate"
          exit="initial"
        >
          {helmet}
          <motion.div variants={variantsPageSection} key="Header">
            <CategoryHeaderSection category={category} id={id as string} />
          </motion.div>

          {/* Category Catalog */}

          <motion.div
            variants={variantsPageSection}
            key="Catalog"
            className="catalog catalog--page"
          >
            <div className="container">
              {/* Catalog */}
              <div className="row">
                <div className="col-12">
                  {/* Catalog Header ::: Form Part */}
                  <CatalogHeader
                    genres={genres}
                    selectedGenre={
                      category === "Genre" && id ? id : "All Genres"
                    }
                    sortOption={sortOption}
                    onGenreChange={onGenreChange}
                    onSortChange={onSortChange}
                  />

                  {/* Catalog Data */}
                  <AnimatePresence>
                    <motion.div
                      variants={variantsListContainer}
                      initial="initial"
                      animate="animate"
                      exit="initial"
                    >
                      <div className="row row--grid">
                        {filteredMovies.length > 0 &&
                          filteredMovies
                            .slice(0, 24)
                            .map((movie, index) => (
                              <CatalogMovieCard
                                key={movie.slug}
                                index={index}
                                movie={movie}
                                watchList={watchList}
                              />
                            ))}
                      </div>
                    </motion.div>
                  </AnimatePresence>
                </div>
              </div>

              {filteredMovies.length > 24 && (
                <div className="row">
                  <div className="col-12">
                    <button className="catalog__more" type="button">
                      Load more
                    </button>
                  </div>
                </div>
              )}
            </div>
          </motion.div>

          {/* Home ::: Subscriptions Movies >>> Logged In User */}
          {isAuthenticated && (
            <motion.section
              variants={variantsPageSection}
              key="Watch List Movies"
              className="section section--gradient"
            >
              <WatchListSection />
            </motion.section>
          )}
        </motion.div>
      </Layout>
    </>
  );
};

export default MoviesCategory;

const helmet = (
  <Helmet>
    <title>All Movies - Watch TV</title>
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
    />

    <script type="text/javascript" src="/assets/js/main.js"></script>
  </Helmet>
);
