import React from "react";

const PricingHeaderSection = () => {
  return (
    <section className="section section--head section--head-fixed">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6">
            <h1 className="section__title section__title--head">
              Pricing plans
            </h1>
          </div>

          <div className="col-12 col-xl-6">
            <ul className="breadcrumb">
              <li className="breadcrumb__item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb__item breadcrumb__item--active">
                Pricing plans
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingHeaderSection;
