import SitePreloader from "../components/Loaders/SitePreloader";
import About from "../pages/About/About";
import Auth from "../pages/Auth/Auth";
import Home from "../pages/Home/Home";
import Movie from "../pages/Movie/Movie";
import Movies from "../pages/Movies/Movies";
import MoviesCategory from "../pages/MoviesCategory/MoviesCategory";
import Pricing from "../pages/Pricing/Pricing";
import Profile from "../pages/Profile/Profile";

const routes = [
  {
    path: "/SignIn",
    exact: true,
    component: Auth,
    privateRoute: false,
    routeIsSignIn: true,
  },
  {
    path: "/SignUp",
    exact: true,
    component: Auth,
    privateRoute: false,
    routeIsSignIn: false,
  },
  {
    path: "/Home",
    exact: true,
    component: Home,
    privateRoute: false,
  },
  {
    path: "/Pricing",
    exact: true,
    component: Pricing,
    privateRoute: false,
  },
  {
    path: "/Movies",
    exact: true,
    component: Movies,
    privateRoute: false,
  },
  {
    path: [
      "/Movies/:category/:id/:sort_option",
      "/Movies/:category/:id",
      "/Movies/All/:id",
      "/Movies/All",
    ],
    exact: true,
    component: MoviesCategory,
    privateRoute: false,
  },
  {
    path: "/Movie/v/:_id",
    exact: true,
    component: Movie,
    privateRoute: false,
  },
  {
    path: "/Profile",
    exact: false,
    component: Profile,
    privateRoute: true,
  },
  {
    path: "/AboutUs",
    exact: false,
    component: About,
    privateRoute: false,
  },
  {
    path: "/Loader",
    exact: true,
    component: SitePreloader,
    privateRoute: false,
  },
];

export default routes;
