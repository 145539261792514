import Slider from "react-slick";
import CarouselSlider from "./Sliders/CarouselSlider";
import { SliderNavBackwardIcon, SliderNavForwardIcon } from "../../images";
import { movieSliderSettings } from "./utils";
import SubscriptionCarouselMovieCard from "./Cards/SubscriptionCarouselMovieCard";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";

const SubscriptionCarousel = () => {
  const movies = useSelector(
    (state: RootState) => state.movies.watchlist_movies
  );

  var sliderRef: Slider | null;
  return (
    <>
      <div className="col-12">
        <div className="section__carousel-wrap">
          <CarouselSlider ref={(c) => (sliderRef = c)} {...movieSliderSettings}>
            {movies.map((movie) => (
              <div key={movie.slug} className="movie__card__slide">
                <SubscriptionCarouselMovieCard movie={movie} />
              </div>
            ))}
          </CarouselSlider>

          <button
            className="section__nav section__nav--cards section__nav--prev"
            type="button"
            onClick={() => sliderRef?.slickPrev()}
          >
            <SliderNavBackwardIcon />
          </button>
          <button
            className="section__nav section__nav--cards section__nav--next"
            onClick={() => sliderRef?.slickNext()}
            type="button"
          >
            <SliderNavForwardIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionCarousel;
