import React from "react";
import { PlayIcon } from "../../../images";
import { MovieDetailResult } from "../../utils/movie/types";
import toYoutubeUrl from "../../utils/movie/toYoutubeUrl";

type Props = {
  isAuthenticated: boolean;
  movieDetails: MovieDetailResult | null;
};
const MovieWatchLink = ({ isAuthenticated, movieDetails }: Props) => {
  return (
    <a
      href={
        isAuthenticated
          ? movieDetails?.homepage
          : toYoutubeUrl(movieDetails?.trailer ? movieDetails.trailer[0] : "")
      }
      className="article__trailer"
      target="_blank"
      rel="noreferrer"
    >
      <PlayIcon />
      Watch{!isAuthenticated && " Trailer"}
    </a>
  );
};

export default MovieWatchLink;
