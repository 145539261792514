import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RatingsIcon, SaveIcon } from "../../../images";
import { updateWatchList } from "../../../store/actions/movies.actions";
import { RootState } from "../../../store/reducers";
import { IMovie } from "../../../store/reducers/movies/types";
import ImagePreloader from "../../Loaders/PreLoaders/ImagePreloader";
import RouteLink from "../../utils/RouteLink";

export type Props = {
  movie: IMovie;
  watchList: string[];
};

const HomeCarouselMovieCard = ({ movie, watchList }: Props) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <div className="home__card">
        <RouteLink to={`/Movie/v/${movie._id}`} className="movie__link">
          {!loaded && <ImagePreloader width={414} height={330} />}
          <img
            src={movie.backdrop_image[1]}
            alt={movie.title}
            style={loaded ? { display: "inherit" } : { display: "none" }}
            onLoad={() => setLoaded(true)}
          />
        </RouteLink>
        <div>
          <h2>{movie.title}</h2>
          <ul>
            <li>Free</li>
            <li>{movie.genres[0]}</li>
            <li>{new Date(movie.release_date).getFullYear()}</li>
          </ul>
        </div>
        {isAuthenticated && (
          <button
            className={`home__add ${watchList.includes(movie._id) && "save"}`}
            type="button"
            onClick={() => {
              dispatch(updateWatchList(movie._id));
            }}
          >
            <SaveIcon />
          </button>
        )}
        <span className="home__rating">
          <RatingsIcon /> {movie.rating}
        </span>
      </div>
    </>
  );
};

export default HomeCarouselMovieCard;
