import React from "react";
import MovieCarousel from "../MovieCarousel";
import { MoviesStateKey } from "../../../store/reducers/movies/types";
import RouteLink from "../../utils/RouteLink";

type Props = {
  moviesStateKey: MoviesStateKey;
  to: string;
  title: string;
};
const MovieCarouselSection = ({ to, title, moviesStateKey }: Props) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="section__title">
              <RouteLink to={to}>{title}</RouteLink>
            </h2>
          </div>
          <MovieCarousel key={title} moviesStateKey={moviesStateKey} />
        </div>
      </div>
    </>
  );
};

export default React.memo(MovieCarouselSection);
