import Slider from "react-slick";
import styled from "styled-components";

const HomeCarouselSlider = styled(Slider)`
  z-index: 2;
  position: relative;
  & .slick-slide {
    margin: auto;
    & .home__card__slide {
      @media (min-width: 576px) {
        margin-left: 10px !important;
        margin-right: 10px !important;
      }
      @media (min-width: 768px) {
        margin-left: 20px !important;
        margin-right: 20px !important;
      }
      @media (min-width: 1200px) {
        margin-left: 20px !important;
        margin-right: 20px !important;
      }
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
  }
  & .slick-arrow {
    height: 100%;
    width: 200px;
    opacity: 0.6;
    transition: opacity 0.5s ease !important;
    animation: fadeIn ease 2s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0.6;
      }
    }
    &:before {
      font-size: 50px;
      color: #2f81ed;
    }
    &:hover {
      opacity: 1;
      :before {
        opacity: 0.6;
      }
    }
    &.slick-prev {
      z-index: 1 !important;
      background-image: linear-gradient(90deg, #131720, transparent);
      &:hover {
        background-image: linear-gradient(90deg, #131720, transparent);
      }
    }
    &.slick-next {
      background-image: linear-gradient(90deg, transparent, #131720);
      &:hover {
        background-image: linear-gradient(90deg, transparent, #131720);
      }
    }
    @media (max-width: 576px) {
      width: 100px;
      &:before {
        font-size: 30px;
      }
    }
  }
  & .movie__link {
    cursor: pointer !important;
  }
`;

export default HomeCarouselSlider;
