import React from "react";
// 3rd Parties
import CarouselSlider from "./Sliders/CarouselSlider";
// Assets
// Types and utils
import { originalsMovieSliderSettings } from "./utils";
import OriginalsCarouselMovieCard from "./Cards/OriginalsCarouselMovieCard";
import { RootState } from "../../store/reducers";
import { useSelector } from "react-redux";
import OriginalsCarouselLoader from "../Loaders/PreLoaders/OriginalsCarouselLoader";

/**
 *
 */
const OriginalsMovieCarousel = () => {
  const movies = useSelector((state: RootState) =>
    state.movies.movies.slice(0, 15)
  );

  if (!(movies.length > 0))
    return (
      <>
        <OriginalsCarouselLoader />
      </>
    );

  return (
    <>
      <div className="col-12">
        <div className="section__carousel-wrap">
          <CarouselSlider {...originalsMovieSliderSettings}>
            {movies.map((movie) => (
              <div key={movie.slug} className="originals__card__slide">
                <OriginalsCarouselMovieCard movie={movie} />
              </div>
            ))}
          </CarouselSlider>
        </div>
      </div>
    </>
  );
};

export default OriginalsMovieCarousel;
