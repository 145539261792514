import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./App.scss";
import routes from "./constants/routes";
import { getMovies, getWatchList } from "./store/actions/movies.actions";
import { RootState } from "./store/reducers";
import { IsTokenValid } from "./utils";
import PrivateRoute from "./components/utils/PrivateRoute";
import { HelmetProvider } from "react-helmet-async";
import SitePreloader from "./components/Loaders/SitePreloader";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import LoadingBar from "react-top-loading-bar";
import { LoadingBarRef } from "./types";
import NotFound from "./pages/NotFound/NotFound";

function App() {
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(IsTokenValid(dispatch));
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );
  const moviesCount = useSelector(
    (state: RootState) => state.movies.movies.length
  );

  const routeStatus = useSelector((state: RootState) => state.route.status);
  const location = useLocation();
  const loadingBarRef = createRef<LoadingBarRef>();

  useEffect(() => {
    dispatch(getMovies());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && moviesCount > 0) {
      dispatch(getWatchList());
    }
  }, [dispatch, isAuthenticated, moviesCount]);

  useEffect(() => {
    setIsAuth(IsTokenValid(dispatch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isAuthenticated]);

  useEffect(() => {
    if (routeStatus === "STARTED") {
      loadingBarRef?.current?.continuousStart(35);
    } else if (routeStatus === "DONE") {
      loadingBarRef?.current?.complete();
    }
  }, [routeStatus, loadingBarRef]);

  return (
    <div className="app">
      <SitePreloader />
      <LoadingBar color="#2f80ed" ref={loadingBarRef} shadow={true} />
      <Route
        render={({ location }) => (
          <HelmetProvider>
            <AnimateSharedLayout type="crossfade">
              <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.key}>
                  {routes.map(
                    (
                      {
                        component: Component,
                        exact,
                        path,
                        privateRoute,
                        ...rest
                      },
                      index
                    ) =>
                      privateRoute ? (
                        <PrivateRoute
                          key={index}
                          exact={exact}
                          path={path}
                          component={(props: any) => (
                            <Component {...props} {...rest} />
                          )}
                          isAuth={isAuth}
                        />
                      ) : (
                        <Route
                          key={index}
                          exact={exact}
                          path={path}
                          component={(props: any) => (
                            <Component {...props} {...rest} />
                          )}
                        />
                      )
                  )}
                  <Route
                    path="/"
                    exact
                    render={() => <Redirect to="/Home" />}
                  />
                  <Route component={NotFound} />
                </Switch>
              </AnimatePresence>
            </AnimateSharedLayout>
          </HelmetProvider>
        )}
      />
    </div>
  );
}

export default App;
