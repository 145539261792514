import {
  Store,
  CombinedState,
  applyMiddleware,
  compose,
  createStore,
} from "redux";
import reduxThunk from "redux-thunk";
// Reducers and types
import RootReducers from "./reducers";
import {
  AuthActionTypes,
  AuthDispatchType,
  AuthState,
} from "./reducers/auth/types";
import {
  MoviesActionTypes,
  MoviesDispatchType,
  MovieState,
} from "./reducers/movies/types";
import {
  RouteActionTypes,
  RouteDispatchType,
  RouteState,
} from "./reducers/route/types";

const store: Store<
  CombinedState<{
    auth: AuthState;
    movies: MovieState;
    route: RouteState;
  }>,
  // ACTION TYPES: if there are more, Add with '|'
  AuthActionTypes | MoviesActionTypes | RouteActionTypes
> & {
  // DISPATCH TYPES: if there are more, Add with '|'
  dispatch: AuthDispatchType | MoviesDispatchType | RouteDispatchType;
} = createStore(RootReducers, compose(applyMiddleware(reduxThunk)));

export default store;

// store.ts
export type AppDispatch = typeof store.dispatch;
