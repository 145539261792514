import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { ArrowHeadDownIcon } from "../../images";
import { variantsSelectDropdownFade } from "../../pages/utils/motionVariants";

type Props = {
  options: string[];
  value_name: string;
  value: string;
  onChange: (value: string) => void;
};

const Select = ({ options, value_name, value, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleList = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: string) => () => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="select">
      <SelectContainer>
        <SelectHeader onClick={toggleList} className={isOpen ? "open" : ""}>
          <span className="select__header__content">
            {value_name}: {value}{" "}
          </span>
          <SelectHeaderArrow>
            <ArrowHeadDownIcon />
          </SelectHeaderArrow>
        </SelectHeader>

        <AnimatePresence>
          {isOpen && (
            <SelectListContainer
              variants={variantsSelectDropdownFade}
              initial="initial"
              animate="animate"
              exit="initial"
            >
              <SelectList>
                {options.map((option) => (
                  <ListItem
                    className={value === option ? "selected" : ""}
                    onClick={onOptionClicked(option)}
                    key={Math.random()}
                  >
                    {option}
                  </ListItem>
                ))}
              </SelectList>
            </SelectListContainer>
          )}
        </AnimatePresence>
      </SelectContainer>
    </div>
  );
};

export default Select;

const SelectContainer = styled("div")`
  width: 100% !important;
  //   height: 44px;
  margin-bottom: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;
const SelectHeader = styled("div")`
  border: none;
  height: 44px;
  color: #fff;
  padding: 10px 0px 0px 10px;
  background-color: #151f30;
  /* width: 100%; */
  font-size: 14px;
  border-radius: 16px;

  outline: none !important;
  font-variant: normal !important;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  &.open {
    border-radius: 16px 16px 0px 0px;
  }
  & .select__header__content {
    /* width: 100%; */

    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #2f80ed;
      outline: 1px solid #151f30;

      border-radius: 2px;
      cursor: pointer;

      border-left: 16px transparent solid;
      border-right: 16px transparent solid;
      background-clip: padding-box;
    }
    &::-webkit-scrollbar-track {
      background: #151f30;
      border-left: 16px transparent solid;
      border-right: 16px transparent solid;
      background-clip: padding-box;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
`;
const SelectHeaderArrow = styled.span`
  height: 44px;
  top: 0;
  right: 0;
  float: right;
  width: 40px;

  & svg {
    fill: #2f80ed;
  }
  .open & svg {
    transform: rotate(180deg);
  }
`;
const SelectListContainer = styled(motion.div)`
  padding-bottom: 10px;
  padding-right: 10px;
  position: absolute;
  /* width: 100%; */

  background-color: #151f30;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  max-height: 220px;

  z-index: 1051 !important;
`;
const SelectList = styled("ul")`
  display: block;
  padding: 10px 20px;

  left: 0;
  box-sizing: border-box;
  /* width: 100%; */

  max-height: 200px;
  overflow-y: auto;

  z-index: 1051;

  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2f80ed;
    outline: 1px solid #151f30;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #151f30;
  }
`;
const ListItem = styled("li")`
  padding: 0;
  line-height: 40px;
  font-size: 14px;
  color: #e0e0e0;
  line-height: 40px;
  display: block;
  transition: 0.5s;
  /* When the value is longer than the space */
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  cursor: pointer;

  &.selected {
    background-color: transparent;
    color: #2f80ed;
    cursor: default;
  }
`;
