import React, { ComponentType } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";

type Props = {
  exact?: boolean;
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  path?: string | string[];
  isAuth: boolean;
};

const PrivateRoute = ({
  component: Component,
  isAuth,
  ...routeProps
}: Props) => {
  return (
    <>
      <Route
        {...routeProps}
        render={(props) =>
          isAuth ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    </>
  );
};

export default PrivateRoute;
