import { IMovie } from "../../store/reducers/movies/types";

export const genres = [
  "Action",
  "Animation",
  "Comedy",
  "Crime",
  "Drama",
  "Experimental",
  "Fantasy",
  "Historical",
  "Horror",
  "Romance",
  "Science Fiction",
  "Thriller",
  "Western",
  "Others",
];

export const getFilteredMovies = (
  category: string,
  id: string | undefined,
  setFilteredMovies: React.Dispatch<React.SetStateAction<IMovie[]>>,
  setSortOption: React.Dispatch<React.SetStateAction<string>>,
  movies: IMovie[]
) => {
  if (category && category.toLowerCase() === "genre") {
    if (id) {
      if (id === "All Genres") setFilteredMovies(movies);
      else
        setFilteredMovies(movies.filter((movie) => movie.genres.includes(id)));
    }
  } else {
    if (category === "All" && id) setSortOption(id);

    setFilteredMovies(sortMovies(movies, id as string));
  }
};

export const sortMovies = (movies: IMovie[], sortOption: string) => {
  switch (sortOption) {
    case "Newest":
      return movies.sort(
        (a, b) => +new Date(b.release_date) - +new Date(a.release_date)
      );
    case "Popular":
      return movies.sort((a, b) => b.rating - a.rating);
    case "Featured":
      // todo: Get Condition for Featured movies sorting
      return movies.sort((a, b) => b.rating - a.rating);
    default:
      return movies;
  }
};
