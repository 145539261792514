import React, { useState } from "react";
import styled from "styled-components";
import ImagePreloader from "../../../components/Loaders/PreLoaders/ImagePreloader";
import RouteLink from "../../../components/utils/RouteLink";
import { GenreData } from "../utils";

type Props = {
  genreData: GenreData;
};
const GenreOverviewCard = ({ genreData }: Props) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
        <RouteLink to={`/Movies/Genre/${genreData.genre}`} className="category">
          <div className="category__cover">
            {!loaded && <ImagePreloader width={414} height={280} />}
            <ImageFadeIn
              src={
                genreData.movies[
                  Math.floor(Math.random() * genreData.movies.length)
                ]?.backdrop_image[0]
              }
              style={loaded ? { display: "inherit" } : { display: "none" }}
              className={loaded ? "show" : "hide"}
              alt={genreData.genre}
              onLoad={() => setLoaded(true)}
            />
          </div>
          <h3 className="category__title">{genreData.genre}</h3>
          <span className="category__value">{genreData.size}</span>
        </RouteLink>
      </div>
    </>
  );
};

export default GenreOverviewCard;

const ImageFadeIn = styled.img`
  animation: fadeIn ease 1s;
  &.show {
    opacity: 1;
    position: relative;
  }
  &.hide {
    opacity: 0;
    position: absolute;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
