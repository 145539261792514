import React from "react";
import SubscriptionTable from "../../../components/Subscription";

const SubscriptionPlansSection = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="section__title section__title--center">
              Select Your Plan
            </h2>
            <p className="section__text section__text--center">
              No hidden fees, equipment rentals, or installation appointments.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <SubscriptionTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlansSection;
