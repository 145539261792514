export const subscriptionTableHeadData = [
  { title: "REGULAR", price: 7.99 },
  { title: "PREMIUM", price: 14.99 },
  { title: "PREMIUM + TV Channels", price: 24.99 },
];

export const subscriptionTableData = [
  {
    details: "WatchTV Originals",
    packages: { regular: true, premium: true, premiumPlus: true },
  },
  {
    details: "No ads",
    packages: { regular: true, premium: true, premiumPlus: true },
  },
  {
    details: "Watch Live TV online and on supported devices",
    packages: { regular: true, premium: true, premiumPlus: true },
  },
  {
    details: "Switch plans or cancel anytime",
    packages: { regular: true, premium: true, premiumPlus: true },
  },
  {
    details: "Record live TV with 50 hours of Cloud DVR storage",
    packages: { regular: false, premium: true, premiumPlus: true },
  },
  {
    details: "Stream 65+ top Live",
    packages: { regular: false, premium: true, premiumPlus: true },
  },
  {
    details: "TV Channels",
    packages: { regular: false, premium: false, premiumPlus: true },
  },
];
