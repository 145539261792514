import { SET_ROUTE_STATUS } from "../../store/reducers/route/actionTypes";
import {
  RouteDispatchType,
  ROUTE_STATUS,
} from "../../store/reducers/route/types";

const setRouteStatus = (status: ROUTE_STATUS) => (
  dispatch: RouteDispatchType
) => {
  dispatch({ type: SET_ROUTE_STATUS, payload: status });
};

export default setRouteStatus;
