import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

const splashBox = {
  initial: {
    height: "100vh",
    bottom: 0,
  },
  animate: {
    height: 0,
    transition: {
      when: "afterChildren",
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const logoSvg = {
  initial: {
    opacity: 1,
    y: 0,
  },
  animate: {
    opacity: 0,
    y: -30,
    transition: {
      staggerChildren: 0.15,
      delay: 0.5,
      duration: 1.5,
      when: "afterChildren",
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const logoPath = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const SitePreloader = React.memo(() => {
  return (
    <AnimDiv
      className="relative z-50 w-full bg-black"
      initial="initial"
      animate="animate"
      onAnimationStart={() => window.scrollTo(0, 0)}
      variants={splashBox}
    >
      <motion.svg
        variants={logoSvg}
        onAnimationComplete={() => {
          document.getElementById("logoAnim")?.classList.add("display-none");
        }}
        version="1.1"
        id="logoAnim"
        x="0px"
        y="0px"
        viewBox="0 0 143.43 33"
      >
        <g>
          <motion.path
            variants={logoPath}
            className="st0"
            d="M19.78,29.14c-0.58,0-1.09-0.41-1.21-0.97l-1.25-5.67l-1.97-8.4h-0.12l-3.25,14.08
		c-0.13,0.56-0.63,0.96-1.21,0.96H7.13c-0.55,0-1.04-0.37-1.19-0.9l-4.7-16.52c-0.23-0.79,0.37-1.58,1.19-1.58h2.57
		c0.56,0,1.06,0.38,1.2,0.93l1.95,7.49c0.35,1.51,0.71,3.59,1.07,6.24h0.1c0.05-0.86,0.24-2.23,0.6-4.1l0.26-1.41
		c0.01-0.03,0.01-0.05,0.02-0.08l2.1-8.14c0.14-0.55,0.64-0.93,1.2-0.93h3.78c0.57,0,1.07,0.39,1.21,0.95l1.99,8.13
		c0.01,0.02,0.01,0.04,0.01,0.07c0.05,0.25,0.11,0.61,0.21,1.07c0.1,0.49,0.19,1.01,0.28,1.56s0.17,1.09,0.25,1.62
		c0.07,0.53,0.12,0.95,0.13,1.27h0.1c0.1-0.82,0.28-1.93,0.54-3.36c0.26-1.42,0.45-2.38,0.56-2.88l2.03-7.5
		c0.15-0.54,0.64-0.92,1.2-0.92h2.48c0.83,0,1.42,0.79,1.19,1.58l-4.76,16.52c-0.15,0.53-0.64,0.9-1.19,0.9H19.78z"
          />
          <motion.path
            variants={logoPath}
            className="st0"
            d="M45.88,29.14c-0.51,0-0.97-0.31-1.16-0.79l-0.7-1.79H43.9c-0.87,1.1-1.77,1.86-2.69,2.29
		c-0.92,0.43-2.13,0.64-3.61,0.64c-1.83,0-3.26-0.52-4.31-1.56c-1.05-1.04-1.57-2.53-1.57-4.45c0-2.02,0.71-3.5,2.12-4.46
		c1.41-0.96,3.54-1.49,6.38-1.59l3.3-0.1v-0.83c0-1.93-0.99-2.89-2.96-2.89c-1.26,0-2.7,0.31-4.32,0.94
		c-0.59,0.23-1.27-0.04-1.55-0.61l-0.64-1.32c-0.32-0.65-0.01-1.43,0.66-1.71c1.86-0.77,3.88-1.15,6.06-1.15
		c2.55,0,4.5,0.56,5.86,1.67c1.36,1.11,2.04,2.8,2.04,5.07V27.9c0,0.69-0.56,1.24-1.24,1.24H45.88z M43.51,21.61
		c0-0.7-0.58-1.26-1.28-1.24L41.5,20.4c-1.51,0.05-2.63,0.32-3.37,0.82c-0.74,0.5-1.1,1.26-1.1,2.28c0,1.46,0.84,2.19,2.52,2.19
		c1.2,0,2.16-0.35,2.88-1.04s1.08-1.61,1.08-2.75V21.61z"
          />
          <motion.path
            variants={logoPath}
            className="st0"
            d="M62,25.35c0.54,0,1.14-0.07,1.8-0.21c0.76-0.16,1.46,0.45,1.46,1.22v1.39c0,0.55-0.36,1.03-0.88,1.19
		c-1.11,0.36-2.41,0.53-3.88,0.53c-2.07,0-3.58-0.52-4.53-1.57c-0.95-1.05-1.42-2.62-1.42-4.72v-7.92c0-0.69-0.56-1.24-1.24-1.24h0
		c-0.69,0-1.24-0.56-1.24-1.24v-0.25c0-0.43,0.23-0.84,0.6-1.06l1.89-1.15c0.24-0.14,0.42-0.37,0.52-0.63l1.04-2.8
		c0.18-0.49,0.64-0.81,1.16-0.81h1.21c0.69,0,1.24,0.56,1.24,1.24v1.56c0,0.69,0.56,1.24,1.24,1.24h2.84c0.69,0,1.24,0.56,1.24,1.24
		v1.41c0,0.69-0.56,1.24-1.24,1.24h-2.84c-0.69,0-1.24,0.56-1.24,1.24v7.92c0,0.74,0.21,1.28,0.62,1.63
		C60.77,25.17,61.32,25.35,62,25.35z"
          />
          <motion.path
            variants={logoPath}
            className="st0"
            d="M76.83,29.48c-5.92,0-8.87-3.25-8.87-9.74c0-3.23,0.8-5.7,2.41-7.4c1.61-1.71,3.92-2.56,6.92-2.56
		c1.73,0,3.31,0.27,4.76,0.8c0.65,0.24,0.97,0.97,0.73,1.61l-0.66,1.74c-0.24,0.63-0.94,0.95-1.58,0.72
		c-0.4-0.14-0.78-0.27-1.14-0.37c-0.7-0.21-1.41-0.31-2.11-0.31c-2.7,0-4.05,1.92-4.05,5.75c0,3.72,1.35,5.58,4.05,5.58
		c1,0,1.92-0.13,2.77-0.4c0.27-0.08,0.54-0.18,0.8-0.3c0.83-0.35,1.75,0.23,1.75,1.13v1.64c0,0.44-0.23,0.86-0.61,1.07
		c-0.64,0.34-1.28,0.59-1.93,0.75C79.21,29.38,78.13,29.48,76.83,29.48z"
          />
          <motion.path
            variants={logoPath}
            className="st0"
            d="M103.31,29.14h-2.7c-0.69,0-1.24-0.56-1.24-1.24v-9.86c0-2.74-1.02-4.11-3.06-4.11c-1.45,0-2.5,0.49-3.15,1.48
		c-0.65,0.99-0.97,2.58-0.97,4.79v7.7c0,0.69-0.56,1.24-1.24,1.24h-2.7c-0.69,0-1.24-0.56-1.24-1.24V3.93
		c0-0.69,0.56-1.24,1.24-1.24h2.7c0.69,0,1.24,0.56,1.24,1.24v4.15c0,0.42-0.04,1.41-0.12,2.96l-0.12,1.53h0.27
		c1.16-1.86,2.99-2.79,5.51-2.79c2.23,0,3.93,0.6,5.08,1.8s1.73,2.92,1.73,5.17V27.9C104.55,28.58,104,29.14,103.31,29.14z"
          />
        </g>

        <g>
          <g>
            <motion.path
              variants={logoPath}
              className="st1"
              d="M115.08,28.96h-0.68c-0.93,0-1.69-0.76-1.69-1.69V14.89c0-0.93-0.76-1.69-1.69-1.69h-1.82
			c-0.93,0-1.69-0.76-1.69-1.69v0c0-0.93,0.76-1.69,1.69-1.69h11.08c0.93,0,1.69,0.76,1.69,1.69v0c0,0.93-0.76,1.69-1.69,1.69h-1.82
			c-0.93,0-1.69,0.76-1.69,1.69v12.39C116.77,28.2,116.02,28.96,115.08,28.96z"
            />
            <motion.path
              variants={logoPath}
              className="st1"
              d="M138.15,9.82h0.5c1.16,0,1.97,1.14,1.6,2.23l-5.36,15.77c-0.23,0.68-0.88,1.15-1.6,1.15h-2.01
			c-0.72,0-1.37-0.46-1.6-1.15l-5.35-15.77c-0.37-1.1,0.44-2.23,1.6-2.23h0.5c0.74,0,1.39,0.48,1.61,1.18l3.23,10.21
			c0.2,0.67,0.41,1.46,0.62,2.35s0.35,1.52,0.4,1.87c0.1-0.8,0.42-2.21,0.98-4.22l3.25-10.21C136.76,10.29,137.41,9.82,138.15,9.82z
			"
            />
          </g>
        </g>
      </motion.svg>
    </AnimDiv>
  );
});

const AnimDiv = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;

  position: fixed;
  z-index: 1050;
  height: 100vh;
  bottom: 0;
  width: 100%;
  background-color: #1a1f3b;

  & svg {
    &.display-none {
      display: none;
    }
    position: absolute;
    z-index: 1050;
    width: 200px;
    enable-background: new 0 0 143.43 33;
    .st0 {
      fill: #ffffff;
    }
    .st1 {
      fill: #2f80ed;
    }
  }
`;

export default SitePreloader;
