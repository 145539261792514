import React from "react";
import HomeCarousel from "../../../components/Carousels/HomeCarousel";
import { MoviesStateKey } from "../../../store/reducers/movies/types";

type Props = {
  moviesStateKey: MoviesStateKey;
};
const TopRatedSection = ({ moviesStateKey }: Props) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="home__title">
              <b>Top Rated Movies</b> of this season
            </h1>
          </div>
        </div>
      </div>

      <HomeCarousel moviesStateKey={moviesStateKey} />
    </>
  );
};

export default React.memo(TopRatedSection);
