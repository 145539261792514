import { AvailableIcon, NotAvailableIcon } from "../../images";
import RouteLink from "../utils/RouteLink";
import { subscriptionTableData, subscriptionTableHeadData } from "./utils";

const SubscriptionTable = () => {
  return (
    <div className="plans">
      <div className="table-responsive">
        <table className="plans__table">
          <thead>
            <tr>
              <th></th>
              {subscriptionTableHeadData.map((headInfo) => (
                <th key={headInfo.title}>
                  <div className="plans__head">
                    <b>{headInfo.title}</b>
                    <p>£{headInfo.price}</p>
                    <span>/ month</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {subscriptionTableData.map((rowData) => (
              <tr key={rowData.details}>
                <td>
                  <span className="plans__title">{rowData.details}</span>
                </td>
                <td>
                  <span
                    className={`plans__status ${
                      rowData.packages.regular
                        ? "plans__status--green"
                        : "plans__status--red"
                    }`}
                  >
                    {rowData.packages.regular ? (
                      <AvailableIcon />
                    ) : (
                      <NotAvailableIcon />
                    )}
                  </span>
                </td>
                <td>
                  <span
                    className={`plans__status ${
                      rowData.packages.premium
                        ? "plans__status--green"
                        : "plans__status--red"
                    }`}
                  >
                    {rowData.packages.premium ? (
                      <AvailableIcon />
                    ) : (
                      <NotAvailableIcon />
                    )}
                  </span>
                </td>
                <td>
                  <span
                    className={`plans__status ${
                      rowData.packages.premiumPlus
                        ? "plans__status--green"
                        : "plans__status--red"
                    }`}
                  >
                    {rowData.packages.premiumPlus ? (
                      <AvailableIcon />
                    ) : (
                      <NotAvailableIcon />
                    )}
                  </span>
                </td>
              </tr>
            ))}

            {/* Table Action */}
            <tr>
              <td></td>
              {subscriptionTableHeadData.map((headInfo) => (
                <td key={headInfo.title}>
                  <RouteLink to="/SignUp">
                    <button className="plans__btn" type="button">
                      Select{" "}
                      {headInfo.title.length > 8
                        ? headInfo.title.substring(0, 9)
                        : headInfo.title}
                    </button>
                  </RouteLink>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscriptionTable;
