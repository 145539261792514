import React from "react";
import RouteLink from "../../../components/utils/RouteLink";

type Props = {
  category: string;
  id: string;
};
const CategoryHeaderSection = ({ category, id }: Props) => {
  return (
    <>
      <section className="section section--head section--head-fixed">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6">
              <h1 className="section__title section__title--head">
                {category && category.toLowerCase() === "genre"
                  ? "Genre"
                  : "All Genres"}{" "}
              </h1>
            </div>

            <div className="col-12 col-xl-6">
              <ul className="breadcrumb">
                <li className="breadcrumb__item">
                  <RouteLink to="/Movies">Movies</RouteLink>
                </li>
                {category && (
                  <li className="breadcrumb__item">
                    <RouteLink to={`/Movies/All/Popular`}>{category}</RouteLink>
                  </li>
                )}
                <li className="breadcrumb__item breadcrumb__item--active">
                  {category ? id : "All"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryHeaderSection;
