import { motion } from "framer-motion";
import {
  InstagramSocialIcon,
  LogoImage,
  FacebookSocialIcon,
  TwitterSocialIcon,
} from "../../../images";
import RouteLink from "../../utils/RouteLink";

type Props = {
  isAuthenticated: boolean;
};
const Footer = ({ isAuthenticated }: Props) => {
  return (
    <>
      {/* <AnimatePresence> */}
      <motion.footer
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 1 } }}
        layoutId="footer"
        className="footer"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-8 col-md-3 col-lg-3 col-xl-3 order-4 order-md-4 order-lg-1 order-xl-1 ">
              <div className="footer__flixtv">
                <img
                  src={LogoImage}
                  alt="watchTV"
                  style={{ maxWidth: "300px" }}
                />
              </div>
              <p className="footer__tagline">
                Movies & TV Shows
                <br />
                Connect with us on Social Media.
              </p>

              {/* Footer ::: Social Media */}

              <div className="footer__social">
                <RouteLink to="" target="_blank">
                  <FacebookSocialIcon />
                </RouteLink>
                <RouteLink to="" target="_blank">
                  <TwitterSocialIcon />
                </RouteLink>
                <RouteLink to="" target="_blank">
                  <InstagramSocialIcon />
                </RouteLink>
              </div>
            </div>

            {/* LINKS */}
            <div className="col-4 col-md-3 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-3 order-xl-3   offset-xl-1">
              <h6 className="footer__title">WatchTV</h6>
              <div className="footer__nav">
                {isAuthenticated && <RouteLink to="">My profile</RouteLink>}
                {isAuthenticated && <RouteLink to="">Sign Out</RouteLink>}
                {!isAuthenticated && <RouteLink to="/">Sign In</RouteLink>}
                {!isAuthenticated && (
                  <RouteLink to="">Create an Account</RouteLink>
                )}
                {!isAuthenticated && <RouteLink to="">Pricing plans</RouteLink>}
              </div>
            </div>
            <div className="col-4 col-md-3 col-lg-3 col-xl-3 order-3 order-lg-2 order-md-1 order-xl-3 offset-xl-1 offset-lg-1">
              <div className="row">
                <div className="col-12">
                  <h6 className="footer__title">Browse</h6>
                  <div className="footer__nav">
                    <RouteLink to="">Live TV</RouteLink>
                    <RouteLink to="">TV Shows</RouteLink>
                    <RouteLink to="">Movies</RouteLink>
                    <RouteLink to="">My Library</RouteLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-md-3 col-lg-2 col-xl-2 order-2 order-lg-4 order-md-3 order-xl-4">
              <h6 className="footer__title">Help</h6>
              <div className="footer__nav">
                <RouteLink to="">Account & Billing</RouteLink>
                <RouteLink to="">Plans & Pricing</RouteLink>
                <RouteLink to="">Supported devices</RouteLink>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="footer__content">
                <div className="footer__links">
                  <RouteLink to="">About us</RouteLink>
                  <RouteLink to="">Contact Us</RouteLink>
                  <RouteLink to="">Privacy policy</RouteLink>
                  <RouteLink to="">Terms and conditions</RouteLink>
                </div>
                <small className="footer__copyright">© WatchTV, 2021.</small>
              </div>
            </div>
          </div>
        </div>
      </motion.footer>
      {/* </AnimatePresence> */}
      {/* end footer */}
    </>
  );
};

export default Footer;
