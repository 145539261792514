import React, { useState } from "react";
import { PlayIcon } from "../../../images";
import ImagePreloader from "../../Loaders/PreLoaders/ImagePreloader";
import RouteLink from "../../utils/RouteLink";
import { Props } from "./types";

const OriginalsCarouselMovieCard = ({ movie }: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <div className="originals" style={{ maxWidth: "80vw" }}>
        <RouteLink to={`/Movie/v/${movie._id}`} className="originals__cover">
          {!loaded && (
            <ImagePreloader strictWidth useHeight width={414} height={330} />
          )}
          <img
            src={movie.backdrop_image[1]}
            alt={movie.title}
            onLoad={() => setLoaded(true)}
            style={loaded ? { display: "inherit" } : { display: "none" }}
          />
          <span>
            <PlayIcon />
            {Math.floor(movie.running_time / 60)}h {movie.running_time % 60}m
          </span>
        </RouteLink>
        <h3 className="originals__title">
          <RouteLink to={`/Movie/v/${movie._id}`} style={{ maxWidth: "414px" }}>
            {movie.title}
          </RouteLink>
        </h3>
      </div>
    </>
  );
};

export default OriginalsCarouselMovieCard;
