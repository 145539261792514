import React from "react";
import styled from "styled-components";
const MovieCarouselLoader = () => {
  return (
    <div>
      <CarouselLoader>
        <div className="popular__carousel--loader__item" />
        <div className="popular__carousel--loader__item" />
        <div className="popular__carousel--loader__item" />
        <div className="popular__carousel--loader__item" />
        <div className="popular__carousel--loader__item" />
      </CarouselLoader>
    </div>
  );
};

export default MovieCarouselLoader;

const CarouselLoader = styled.div`
  @keyframes pulseAnim {
    0% {
      background-color: rgba(47, 129, 237, 0.1);
    }
    50% {
      background-color: #131720;
    }
    100% {
      background-color: rgba(47, 129, 237, 0.1);
    }
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  & .popular__carousel--loader__item {
    margin: 20px;
    border-radius: 1rem;
    height: 270px;
    width: 192px !important;
    animation: pulseAnim 1s infinite ease-in-out;

    @media (max-width: 1200px) {
      &:nth-child(1) {
        display: none;
      }
    }
    @media (max-width: 1024px) {
      &:nth-child(2) {
        display: none;
      }
    }
    @media (max-width: 768px) {
      &:nth-child(3) {
        display: none;
      }
    }
  }
`;
