import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const Layout: FC = ({ children }) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );
  return (
    <>
      <Header />
      {children}
      <Footer isAuthenticated={isAuthenticated} />
    </>
  );
};

export default Layout;
