import { IMovie } from "./types";

export const sortTrendingMovies = (moviesArray: IMovie[]) => {
  return moviesArray
    .sort((a, b) => +new Date(b.release_date) - +new Date(a.release_date))
    .slice(0, 10)
    .sort((a, b) => (b.title < a.title ? -1 : a.title > b.title ? 1 : 0));
};
export const sortNewestMovies = (moviesArray: IMovie[]) => {
  return moviesArray
    .sort((a, b) => +new Date(b.release_date) - +new Date(a.release_date))
    .slice(0, 10);
};
export const sortPopularMovies = (moviesArray: IMovie[]) => {
  return moviesArray.sort((a, b) => b.rating - a.rating).slice(0, 10);
};
export const sortActionMovies = (moviesArray: IMovie[]) => {
  return moviesArray
    .filter((movie) => movie.genres.includes("Action"))
    .slice(0, 10)
    .sort((a, b) => b.rating - a.rating);
};
export const sortClassicMovies = (moviesArray: IMovie[]) => {
  return moviesArray
    .sort((a, b) => +new Date(a.release_date) - +new Date(b.release_date))
    .slice(0, 10);
};
