import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { AnimatePresence, motion } from "framer-motion";
// Store
import { RootState } from "../../store/reducers";
// Components
import Layout from "../../components/Layout/Layout";
import TopRatedSection from "./InsideHome/TopRatedSection";
import MovieCarouselSection from "../../components/Carousels/Sections/MovieCarouselSection";
import WatchListSection from "./InsideHome/WatchListSection";
import SubscriptionPlansSection from "./InsideHome/SubscriptionPlansSection";
import OriginalsSection from "./InsideHome/OriginalsSection";
import { variantsPage, variantsPageSection } from "../utils/motionVariants";
import setRouteStatus from "../utils/setRouteStatus";

const Home = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Layout>
        <motion.div
          variants={variantsPage}
          initial="initial"
          animate="animate"
          exit="initial"
        >
          <Helmet>
            <title>Watch TV</title>
            <script type="text/javascript" src="/assets/js/main.js"></script>
          </Helmet>
          <AnimatePresence>
            <HomeBody isAuthenticated={isAuthenticated} />
          </AnimatePresence>
        </motion.div>
      </Layout>
    </>
  );
};

export default Home;

type Props = {
  isAuthenticated: boolean;
};
const HomeBody = React.memo(({ isAuthenticated }: Props) => {
  return (
    <>
      {/* Home ::: Trending Movies  */}
      <motion.div
        variants={variantsPageSection}
        key="Top Rated"
        className="home home--title"
      >
        <TopRatedSection moviesStateKey="trending" />
      </motion.div>

      {/* Home ::: Popular Movies */}
      <motion.section
        variants={variantsPageSection}
        key="Popular"
        className="section"
      >
        <MovieCarouselSection
          title="Popular Movies"
          key="Popular Movies"
          // movies={homePageMovies.popular}
          moviesStateKey="popular"
          to="/Movies/All/Popular"
        />
      </motion.section>

      {/* Home ::: WatchTV Originals */}
      <motion.section
        variants={variantsPageSection}
        key="Originals"
        className="section  section--pb0"
      >
        <OriginalsSection />
      </motion.section>

      {/* Home ::: Genre 1 Movies */}
      <motion.section
        variants={variantsPageSection}
        key="Top action"
        className="section section--pb0"
      >
        <MovieCarouselSection
          title="Top Action Movies"
          key="Top Action Movies"
          moviesStateKey="action"
          to="/Movies/Genre/Action"
        />
      </motion.section>

      {/* Home ::: Genre 2 Movies */}
      <motion.section
        variants={variantsPageSection}
        key="Current Year Movies"
        className="section section--pb0"
      >
        <MovieCarouselSection
          title="Classic Movies"
          key="Classic Movies"
          moviesStateKey="classic"
          to="/Movies"
        />
      </motion.section>

      {/* Home ::: Subscriptions Movies >>> Logged In User */}
      {isAuthenticated && (
        <motion.section
          variants={variantsPageSection}
          key="Watch List Movies"
          className="section section--pb0 section--gradient"
        >
          <WatchListSection />
        </motion.section>
      )}

      {/* Home ::: Select Subscription Plan >>> Without Auth */}
      {!isAuthenticated && (
        <motion.section
          variants={variantsPageSection}
          key="Select Plan"
          className="section section--gradient"
        >
          <SubscriptionPlansSection />
        </motion.section>
      )}
    </>
  );
});
