import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { RootState } from "../../store/reducers";
// Components
import MovieDetails from "./InsideMovie/MovieDetails";
import MovieTrailer from "./InsideMovie/MovieTrailer";
// Utils
import getMovieDetails from "../utils/movie/getMovieDetails";
import { MovieDetailResult } from "../utils/movie/types";
import MovieWatchLink from "./InsideMovie/MovieWatchLink";
import MovieInfoList from "./InsideMovie/MovieInfoList";
import {
  variantsContentFadeIn,
  variantsPage,
  variantsPageSection,
} from "../utils/motionVariants";
import setRouteStatus from "../utils/setRouteStatus";

type Params = { _id: string };

const Movie = () => {
  const params = useParams<Params>();

  const movie = useSelector((state: RootState) =>
    state.movies.movies.find((movie) => movie._id === params._id)
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );

  const [movieDetails, setMovieDetails] = useState<MovieDetailResult | null>(
    null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (movie) {
      getMovieDetails(movie.title, {
        year: new Date(movie.release_date).getFullYear(),
        category: movie.category,
      }).then((result) => setMovieDetails(result));
    }
  }, [movie]);

  return (
    <Layout>
      <motion.div
        variants={variantsPage}
        initial="initial"
        animate="animate"
        exit="initial"
      >
        {helmet(movie ? movie.title : "")}

        {movie && (
          <motion.section
            variants={variantsPageSection}
            key={movie.slug}
            className="section section--head section--head-fixed section--gradient section--details-bg"
          >
            <MovieBackdrop
              variants={variantsContentFadeIn}
              className="section__bg"
              bg={movieDetails?.backdrop_url as string}
            />

            <div className="container">
              <div className="article">
                {/* Details */}
                <div className="row">
                  <div className="col-12 col-xl-8">
                    <MovieWatchLink
                      isAuthenticated={isAuthenticated}
                      movieDetails={movieDetails}
                    />
                    {/* end trailer */}
                    <MovieDetails
                      movie={movie}
                      runtime={movieDetails?.runtime}
                      pgRating={movieDetails?.pg_rating}
                      isAuthenticated={isAuthenticated}
                    />
                    {/* Movie Details*/}
                  </div>

                  {/* Trailer */}
                  {isAuthenticated && movieDetails?.trailer && (
                    <MovieTrailer youtubeId={movieDetails.trailer[0]} />
                  )}

                  <div className="col-12 col-xl-8">
                    {/* Genres */}
                    <MovieInfoList list={movie.genres} title="Genres" />
                    {/* Cast */}
                    {movieDetails && (
                      <MovieInfoList list={movieDetails?.cast} title="Cast" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </motion.section>
        )}
      </motion.div>
    </Layout>
  );
};

export default Movie;

const helmet = (title: string) => (
  <Helmet>
    <title>{`${title ? (title as string) + " - " : ""}Watch TV`}</title>
    <script
      type="text/javascript"
      src="/assets/js/jquery-3.5.1.min.js"
    ></script>
    <script
      type="text/javascript"
      src="/assets/js/jquery.magnific-popup.min.js"
    ></script>
    <script type="text/javascript" src="/assets/js/plyr.min.js"></script>
    <script type="text/javascript" src="/assets/js/main.js"></script>
  </Helmet>
);

const MovieBackdrop = styled(motion.div)<{ bg: string }>`
  background: url(${(props) => props.bg}) center top / cover no-repeat;
`;
