type Props = {
  list: string[];
  title: string;
};
const MovieInfoList = ({ list, title }: Props) => {
  return (
    <>
      <div className="categories">
        <h3 className="categories__title">{title}</h3>
        {list.map((item) => (
          <span key={item} className="categories__item">
            {item}
          </span>
        ))}
      </div>
    </>
  );
};

export default MovieInfoList;
