import styled from "styled-components";

export const SignInputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  height: 44px;
  & .sign__input {
    flex: 0.475;
  }
`;
