import { IMovie } from "../../store/reducers/movies/types";

const genres = [
  "Action",
  "Animation",
  "Comedy",
  "Crime",
  "Drama",
  "Experimental",
  "Fantasy",
  "Historical",
  "Horror",
  "Romance",
  "Science Fiction",
  "Thriller",
  "Western",
  "Others",
];

export type GenreData = { genre: string; movies: IMovie[]; size: number };

export const getGenresData = (moviesArray: IMovie[]) => {
  let genreData: GenreData[] = [];

  genres.forEach((genre) => {
    let genreArray = moviesArray.filter((movie) =>
      movie.genres.includes(genre)
    );
    genreData.push({
      genre: genre,
      movies: genreArray,
      size: genreArray.length,
    });
  });

  return genreData.sort((a, b) => b.size - a.size).slice(0, 8);
};
