import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import MovieCarouselSection from "../../components/Carousels/Sections/MovieCarouselSection";
import Layout from "../../components/Layout/Layout";
import { RootState } from "../../store/reducers";
import { variantsPage, variantsPageSection } from "../utils/motionVariants";
import setRouteStatus from "../utils/setRouteStatus";
import GenresOverviewSection from "./InsideMovies/GenresOverviewSection";
import MoviesHeaderSection from "./InsideMovies/MoviesHeaderSection";
import "./Movies.scss";
import { getGenresData } from "./utils";

const popular = (
  <section key="Popular" className="section section--pb0">
    <MovieCarouselSection
      key="Popular Movies"
      title="Popular Movies"
      moviesStateKey="popular"
      to="Movies/All/Popular"
    />
  </section>
);

const newest = (
  <section key="Newest" className="section section--pb0">
    <MovieCarouselSection
      key="Newest Movies"
      title="Newest Movies"
      moviesStateKey="newest"
      to="Movies/All/Newest"
    />
  </section>
);

const Movies = () => {
  const movies = useSelector((state: RootState) => state.movies.movies);
  const [genresData, setGenresData] = useState(getGenresData(movies));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGenresData(getGenresData(movies));
  }, [movies]);

  return (
    <>
      <Layout>
        <motion.div
          variants={variantsPage}
          initial="initial"
          animate="animate"
          exit="initial"
        >
          <Helmet>
            <title>All Movies - Watch TV</title>

            <script type="text/javascript" src="/assets/css/main.css"></script>
            <script type="text/javascript" src="/assets/js/main.js"></script>
          </Helmet>

          {/* Header Section  */}
          <motion.div variants={variantsPageSection} key="Header">
            <MoviesHeaderSection />
          </motion.div>

          {/* Genres OverView Section */}
          <motion.div variants={variantsPageSection} key="Genre Overview">
            <GenresOverviewSection genresData={genresData} />
          </motion.div>

          {/* Popular Movies */}
          <motion.div variants={variantsPageSection} key="Popular">
            {popular}
          </motion.div>

          {/* Newest Movies */}
          <motion.div variants={variantsPageSection} key="Newest">
            {newest}
          </motion.div>
        </motion.div>
      </Layout>
    </>
  );
};

export default Movies;
