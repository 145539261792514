import axios from "axios";
import handleErrors from "./handleErrors";

interface GetMovieIdOptions {
  /**Your IMDB API Key */
  apiKey: string;
  /**Movie Release Year */
  year?: number | null;
  /**API Request language */
  language: string;
  /**Category of Movie */
  category: "Movie" | "TV Show";
}

/**
 * Funtion to Get Movie Id using Title and Release year
 * @param title MovieTitle
 * @param {options} options
 * @param {apiKey} options.apiKey - Your TMDB API Key
 * @param {year} options.year - Movie Release Year
 * @param {language} options.language - API Request language
 */

const getMovieId = async (
  title: string,
  { apiKey, year, language, category }: GetMovieIdOptions
): Promise<string | null> => {
  const endpoint = `https://api.themoviedb.org/3/search/${
    category === "Movie" ? "movie" : "tv"
  }?api_key=${apiKey}&query=${encodeURIComponent(title)}${
    year ? "&year=" + year : ""
  }&language=${language}`;
  try {
    const response = await axios.get(endpoint);
    if (response.data.status_message !== undefined) {
      throw new TypeError(response.data.status_message);
    }

    // *When there is no results with year provided
    if (response.data.results.length === 0) {
      // *Retry failed search without year
      console.log(endpoint);
      console.log(year);
      var num = 1;
      console.log(num++);
      if (year !== null) {
        const resultWithoutYear = await getMovieId(title, {
          apiKey,
          year: null,
          language,
          category,
        });
        return resultWithoutYear;
      }
      console.log("Throwing Error");

      // ! Error::: No Result with provided query
      throw new Error(
        `No TMDB Movie found with that search query, try searching https://www.themoviedb.org/search?query=${encodeURIComponent(
          title
        )} to verify one exists`
      );
    } else {
      return response.data.results[0].id as string;
    }
  } catch (error) {
    handleErrors(error);
    return null;
  }
};

export default getMovieId;
