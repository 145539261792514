import React, { forwardRef, ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import { LiveIcon, MoreIcon } from "../../../images";
import RouteNavLink from "../../utils/RouteNavLink";
import { HeaderLink } from "./types";

type Props = {
  links: HeaderLink[];
  moreLinks: HeaderLink[];
  toggleNavSmallScreen: boolean;
  isAuthenticated: boolean;
};
const HeaderNav: ForwardRefRenderFunction<HTMLUListElement, Props> = (
  { links, moreLinks, toggleNavSmallScreen, isAuthenticated },
  ref
) => {
  return (
    <ul
      ref={ref}
      className={`header__nav ${toggleNavSmallScreen && "header__nav--active"}`}
    >
      {links.map(
        (link) =>
          ((isAuthenticated && (link.authOnly || !link.nonAuthOnly)) ||
            (!isAuthenticated && (link.nonAuthOnly || !link.authOnly))) && (
            <li
              key={link.name}
              className={`header__nav-item ${
                link.to === "/Live" && "header__nav-link--live"
              }`}
            >
              <HNavLink className="header__nav-link" to={link.to}>
                {link.name}
                {link.to === "/Live" && <LiveIcon />}
              </HNavLink>
            </li>
          )
      )}

      <li className="header__nav-item">
        <span
          className="header__nav-link header__nav-link--more"
          role="button"
          id="dropdownMenu3"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <MoreIcon />
        </span>

        <ul
          className="dropdown-menu header__nav-menu header__nav-menu--scroll"
          aria-labelledby="dropdownMenu3"
        >
          {moreLinks.map(
            (link) =>
              ((isAuthenticated && (link.authOnly || !link.nonAuthOnly)) ||
                (!isAuthenticated && (link.nonAuthOnly || !link.authOnly))) && (
                <li key={link.name}>
                  <HNavLink to={link.to}>{link.name}</HNavLink>
                </li>
              )
          )}
        </ul>
      </li>
    </ul>
  );
};

export default forwardRef(HeaderNav);

const HNavLink = styled(RouteNavLink)`
  &.active {
    color: #2f80ed;
  }
`;
