import { GET_MOVIES, GET_WATCHLIST, UPDATE_WATCHLIST } from "./actionTypes";
import { MoviesActionTypes, MovieState } from "./types";
import {
  sortActionMovies,
  sortClassicMovies,
  sortNewestMovies,
  sortPopularMovies,
  sortTrendingMovies,
} from "./utils";

const INITIAL_STATE: MovieState = {
  movies: [],

  watchlist: [],
  watchlist_movies: [],

  newest: [],
  trending: [],
  popular: [],
  action: [],
  classic: [],
};

const reducer = (
  state: MovieState = INITIAL_STATE,
  action: MoviesActionTypes
): MovieState => {
  switch (action.type) {
    case GET_MOVIES:
      const movies = action.payload;
      return {
        ...state,
        trending: sortTrendingMovies(movies),
        popular: sortPopularMovies(movies),
        action: sortActionMovies(movies),
        classic: sortClassicMovies(movies),
        newest: sortNewestMovies(movies),
        movies: action.payload,
      };
    case GET_WATCHLIST:
      let keys = action.payload.map((item) => item.movieId);
      return {
        ...state,
        watchlist: action.payload,
        watchlist_movies: state.movies.filter((movie) =>
          keys.includes(movie._id)
        ),
      };
    case UPDATE_WATCHLIST:
      keys = action.payload.map((item) => item.movieId);
      return {
        ...state,
        watchlist: action.payload,
        watchlist_movies: state.movies.filter((movie) =>
          keys.includes(movie._id)
        ),
      };
    default:
      return state;
  }
};

export default reducer;
