import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { localStorageUserKey } from "../../../constants/localstorage";
import Logo from "../../../images/Logo";
import { RootState } from "../../../store/reducers";
import RouteLink from "../../utils/RouteLink";
import HeaderNav from "./HeaderNav";
import HeaderSearch from "./HeaderSearch";
import HeaderUser from "./HeaderUser";
import { HeaderLink } from "./types";

const links: HeaderLink[] = [
  { name: "Movies & TV Shows", to: "/Movies" },
  { name: "Pricing", to: "/Pricing", nonAuthOnly: true },
];

const moreLinks: HeaderLink[] = [
  { name: "Profile", to: "/Profile", authOnly: true },
  { name: "About Us", to: "/AboutUs" },
];
const Header = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );
  const userName = JSON.parse(
    localStorage.getItem(localStorageUserKey) as string
  )?.user.name;
  const [toggleNavSmallScreen, setToggleNavSmallScreen] = useState(false);
  const [navHeight, setNavHeight] = useState(0);
  const navRef = useRef(null as null | HTMLUListElement);

  useEffect(() => {
    if (navRef.current) setNavHeight(navRef.current.clientHeight);
  }, [toggleNavSmallScreen]);

  return (
    <header
      className={`header header--hidden`}
      style={toggleNavSmallScreen ? { top: navHeight } : {}}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header__content">
              <button
                className={`header__menu ${
                  toggleNavSmallScreen && "header__menu--active"
                }`}
                type="button"
                onClick={() => setToggleNavSmallScreen((prev) => !prev)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              <RouteLink to={"/Home"} className="header__logo">
                <Logo />
              </RouteLink>

              {/* HeaderNav */}
              <HeaderNav
                ref={navRef}
                links={links}
                moreLinks={moreLinks}
                toggleNavSmallScreen={toggleNavSmallScreen}
                isAuthenticated={isAuthenticated}
              />

              <div className="header__actions">
                {/* HeaderSearch */}
                <HeaderSearch />

                {/* HeaderNavUser */}
                <HeaderUser
                  isAuthenticated={isAuthenticated}
                  userName={userName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
