import React from "react";
import SubscriptionCarousel from "../../../components/Carousels/SubscriptionCarousel";
import RouteLink from "../../../components/utils/RouteLink";

const WatchListSection = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="section__title">
              <RouteLink to="/Profile/Favorites">My Watch List</RouteLink>
            </h2>
          </div>
          {/* Subscriptions = WatchList  */}
          <SubscriptionCarousel />
        </div>
      </div>
    </>
  );
};

export default WatchListSection;
