import React from "react";
// Third Party
// Types
import { MoviesStateKey } from "../../store/reducers/movies/types";
import { homeSliderSettings } from "./utils";
import HomeCarouselSlider from "./Sliders/HomeCarouselSlider";
import HomeCarouselMovieCard from "./Cards/HomeCarouselMovieCard";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import OriginalsCarouselLoader from "../Loaders/PreLoaders/OriginalsCarouselLoader";

type Props = {
  moviesStateKey: MoviesStateKey;
};

const HomeCarousel = ({ moviesStateKey }: Props) => {
  const watchList = useSelector((state: RootState) =>
    state.movies.watchlist.map((watchListItem) => watchListItem.movieId)
  );
  const movies = useSelector(
    (state: RootState) => state.movies[moviesStateKey]
  );

  if (!(movies.length > 0))
    return (
      <>
        <OriginalsCarouselLoader />
      </>
    );
  return (
    <>
      <HomeCarouselSlider key={moviesStateKey} {...homeSliderSettings}>
        {movies.map((movie) => (
          <div key={movie.slug} className="home__card__slide">
            <HomeCarouselMovieCard movie={movie} watchList={watchList} />
          </div>
        ))}
      </HomeCarouselSlider>
    </>
  );
};

export default React.memo(HomeCarousel);
