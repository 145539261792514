import { localStorageUserKey } from "../../../constants/localstorage";
import {
  AUTH_SIGN_IN,
  AUTH_ERROR,
  AUTH_SIGN_OUT,
  CLEAR_ERROR,
  AUTH_STATUS,
  AUTH_SET_USER,
} from "./actionTypes";
import { AuthActionTypes, AuthState } from "./types";

const INITIAL_STATE: AuthState = {
  is_authenticated: false,
  token: "",
  auth_status: "IDLE",
  user: {
    id: "",
    email: "",
    name: {
      first_name: "",
      last_name: "",
    },
  },
  error_message: null,
};

const reducer = (
  state: AuthState = INITIAL_STATE,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    // SET USER FROM LOCAL STORAGE
    case AUTH_SET_USER:
      return {
        ...state,
        is_authenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    //   SIGN IN
    case AUTH_SIGN_IN:
      localStorage.setItem(
        localStorageUserKey,
        JSON.stringify({ ...action.payload })
      );
      return {
        ...state,
        is_authenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    // SIGN OUT
    case AUTH_SIGN_OUT:
      localStorage.clear();
      return INITIAL_STATE;
    // AUTH STATUS
    case AUTH_STATUS:
      return { ...state, auth_status: action.payload };
    //   AUTH ERROR
    case AUTH_ERROR:
      return {
        ...state,
        error_message: action.payload,
      };
    // CLEAR ERRORS
    case CLEAR_ERROR:
      return {
        ...state,
        error_message: null,
      };
    default:
      return state;
  }
};

export default reducer;
