import { useSelector } from "react-redux";
// 3rd Parties
import Slider from "react-slick";
// Components
import MovieCarouselLoader from "../Loaders/PreLoaders/MovieCarouselLoader";
import CarouselSlider from "./Sliders/CarouselSlider";
import CarouselMovieCard from "./Cards/CarouselMovieCard";
// Assets
import { SliderNavBackwardIcon, SliderNavForwardIcon } from "../../images";
// Store, Types and utils
import { RootState } from "../../store/reducers";
import { MoviesStateKey } from "../../store/reducers/movies/types";
import { movieSliderSettings } from "./utils";
import { useState } from "react";

type Props = {
  moviesStateKey: MoviesStateKey;
};

const MovieCarousel = ({ moviesStateKey }: Props) => {
  const movies = useSelector(
    (state: RootState) => state.movies[moviesStateKey]
  );
  const watchList = useSelector((state: RootState) =>
    state.movies.watchlist.map((watchListItem) => watchListItem.movieId)
  );

  const [sliderRef, setSliderRef] = useState<Slider | null>(null);

  if (!(movies.length > 0))
    return (
      <>
        <MovieCarouselLoader />
      </>
    );

  return (
    <>
      <div className="col-12">
        <div className="section__carousel-wrap">
          <CarouselSlider
            key={moviesStateKey}
            ref={(c) => setSliderRef(c)}
            {...movieSliderSettings}
          >
            {movies.map((movie) => (
              <div key={movie.slug} className="movie__card__slide">
                <CarouselMovieCard movie={movie} watchList={watchList} />
              </div>
            ))}
          </CarouselSlider>

          <button
            className="section__nav section__nav--cards section__nav--prev"
            type="button"
            onClick={() => sliderRef?.slickPrev()}
          >
            <SliderNavBackwardIcon />
          </button>
          <button
            className="section__nav section__nav--cards section__nav--next"
            onClick={() => sliderRef?.slickNext()}
            type="button"
          >
            <SliderNavForwardIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default MovieCarousel;
