import axios from "axios";
import getMovieId from "./getMovieId";
import handleErrors from "./handleErrors";
import { MovieDetailResult, MovieResponse, Video } from "./types";

const APIKEY = "77d01fba2d18effb84351218c0d4af8b";
const LANGUAGE = "en-US";

type MovieDetailOptions = {
  /**Your IMDB API Key */
  apiKey?: string;
  /**Movie Release Year */
  year?: number | null;
  /**API Request language */
  language?: string;
  /**Category of Movie */
  category: "Movie" | "TV Show";
};
/**
 * Get Movie Details from TMDB
 * @param title MovieTitle
 * @param {options} options
 * @param {apiKey} options.apiKey - Your TMDB API Key
 * @param {year} options.year - Movie or TV Release Year
 * @param {language} options.language - API Request language
 * @param {category} options.category - Category of Movie
 */
const getMovieDetails = async (
  title: string,
  {
    apiKey = APIKEY,
    year = null,
    language = LANGUAGE,
    category,
  }: MovieDetailOptions
) => {
  const movieId = await getMovieId(title.toLowerCase(), {
    apiKey,
    year,
    language,
    category,
  });

  if (!movieId) return null;

  const result = await getDetails(movieId, { apiKey, language, category });

  if (!result) return null;

  return result;
};

interface GetDetailsOptions extends MovieDetailOptions {
  apiKey: string;
  language: string;
}

/**
 *
 * @param movieId TMDB Movie Id
 * @param {options} options - Options
 * @param {apiKey} options.apiKey - Your TMDB API Key
 * @param {language} options.language - API Request language
 * @param {category} options.category - Category of Movie
 */
const getDetails = async (
  movieId: string,
  { apiKey, language, category }: GetDetailsOptions
): Promise<MovieDetailResult | null> => {
  // Endpoint
  const endpoint = `https://api.themoviedb.org/3/${
    category === "Movie" ? "movie" : "tv"
  }/${encodeURI(
    movieId
  )}?api_key=${apiKey}&append_to_response=videos,credits,release_dates&language=${language}`;

  try {
    // get endpoint
    const response = await axios.get(endpoint);
    if (response.data.status_message !== undefined) {
      throw new TypeError(`movie-trailer: ${response.data.status_message}`);
    }
    const movie = response.data as MovieResponse;
    const videos = movie.videos.results as Video[];

    // Strip needed data
    const movieDetail = {
      homepage: movie.homepage,
      backdrop_url: getBackdropUrl(movie.backdrop_path),
      runtime: category === "Movie" ? movie.runtime : movie.episode_run_time[0],
      trailer: getTrailer(videos ? videos : []),
      cast: movie.credits.cast
        .sort((a, b) => b.popularity - a.popularity)
        .slice(0, 5)
        .map((cast) => cast.name),
      pg_rating:
        movie.release_dates?.results[0].release_dates[0].certification ?? null,
    };

    return movieDetail;
  } catch (error) {
    handleErrors(error);
    return null;
  }
};

const getBackdropUrl = (backdrop_path: string) => {
  const baseImageUrl = "https://image.tmdb.org/t/p/original/";
  return `${baseImageUrl}${backdrop_path}`;
};

const getTrailer = (videos: Video[]) => {
  if (videos.length > 0)
    return videos
      .filter((video) => video.type === "Trailer" || video.type === "Teaser")
      .map((video) => video.key);
  else return null;
};

export default getMovieDetails;
