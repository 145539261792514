import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlayIcon, RatingsIcon, SaveIcon } from "../../../images";
import { updateWatchList } from "../../../store/actions/movies.actions";
import { RootState } from "../../../store/reducers";
import { IMovie } from "../../../store/reducers/movies/types";
import ImagePreloader from "../../Loaders/PreLoaders/ImagePreloader";
import RouteLink from "../../utils/RouteLink";

export type Props = {
  movie: IMovie;
  watchList: string[];
};

const CarouselMovieCard = ({ movie, watchList }: Props) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.is_authenticated
  );

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <div className="card">
        <RouteLink to={`/Movie/v/${movie._id}`} className="card__cover">
          {!loaded && (
            <ImagePreloader strictWidth useHeight width={192} height={270} />
          )}
          <img
            src={movie.cover_image}
            alt={movie.title}
            width={192}
            onLoad={() => setLoaded(true)}
            style={loaded ? { display: "inherit" } : { display: "none" }}
          />
          <PlayIcon />
        </RouteLink>
        {isAuthenticated && (
          <button
            className={`card__add ${watchList.includes(movie._id) && "save"}`}
            type="button"
            onClick={() => {
              dispatch(updateWatchList(movie._id));
            }}
          >
            <SaveIcon />
          </button>
        )}
        <span className="card__rating">
          <RatingsIcon />
          {movie.rating}
        </span>
        <h3 className="card__title" style={{ maxWidth: 192 }}>
          <RouteLink to={`/Movie/v/${movie._id}`}>{movie.title}</RouteLink>
        </h3>
        <div>
          <ul className="card__list">
            <li>{movie.genres[1]?.trim()}</li>
            <li>{movie.genres[0]?.trim()}</li>
            <li>{new Date(movie.release_date).getFullYear()}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CarouselMovieCard;
