import { combineReducers } from "redux";
// Reducers
import authReducer from "./auth/auth.reducer";
import moviesReducer from "./movies/movies.reducer";
import routeReducer from "./route/route.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  movies: moviesReducer,
  route: routeReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
