import React, { useEffect, useState } from "react";
import RouteLink from "../../../components/utils/RouteLink";
import { RatingsIcon } from "../../../images";
import { IMovie } from "../../../store/reducers/movies/types";

type Props = {
  movie: IMovie;
  runtime: number | undefined;
  pgRating: string | undefined;
  isAuthenticated: boolean;
};

const MovieDetails = ({ movie, runtime, pgRating, isAuthenticated }: Props) => {
  const [runtimeToUse, setRuntimeToUse] = useState<number | null>(null);
  useEffect(() => {
    setRuntimeToUse(runtime ? runtime : null);
  }, [runtime]);
  return (
    <div className="article__content">
      <h1>{movie.title}</h1>

      <ul className="list">
        <li>
          <RatingsIcon /> {movie?.rating}
        </li>
        <li>{movie.genres[0]}</li>
        <li>{new Date(movie.release_date as Date).getFullYear()}</li>
        <li>{runtimeToUse && parseRunTime(runtimeToUse)}</li>
        {pgRating && <li> {pgRating} </li>}
      </ul>

      {!isAuthenticated && (
        <div className="article__btns">
          <RouteLink to="/" className="article__btn">
            Subscribe for $11
          </RouteLink>
          <RouteLink to="/Pricing" className="article__btn article__btn--white">
            Pricing plans
          </RouteLink>
        </div>
      )}
      <p>{movie.description}</p>
    </div>
  );
};

/**
 * Convert runtime to string
 * @param time Time to be parsed
 */
const parseRunTime = (time: number) => {
  const hour = Math.floor(time / 60);
  const minutes = time % 60;

  return `${hour !== 0 ? hour + "h " : ""}
  ${minutes !== 0 ? minutes + "m" : ""}`;
};

export default MovieDetails;
