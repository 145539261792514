import React from "react";
import { GenreData } from "../utils";
import GenreOverviewCard from "./GenreOverviewCard";

type Props = {
  genresData: GenreData[];
};
const GenresOverviewSection = ({ genresData }: Props) => {
  return (
    <>
      <section className="section section--pb0">
        <div className="container">
          <div className="row row--grid">
            {genresData.map((genreData) => (
              <GenreOverviewCard key={genreData.genre} genreData={genreData} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default GenresOverviewSection;
