import React, { FormEvent } from "react";
import Select from "../../../../components/DropdownSelect/Select";

type Props = {
  genres: string[];
  sortOption: string;
  selectedGenre: string;
  onGenreChange: (value: string) => void;
  onSortChange: (event: FormEvent<HTMLDivElement>) => void;
};
const CatalogHeader = ({
  genres,
  sortOption,
  selectedGenre,
  onGenreChange,
  onSortChange,
}: Props) => {
  return (
    <div className="catalog__nav">
      <div className="catalog__select-wrap">
        <Select
          onChange={onGenreChange}
          options={["All Genres", ...genres]}
          value_name={"Genre"}
          value={selectedGenre}
        />
      </div>
      {/* </div> */}
      <div className="slider-radio" onChange={onSortChange}>
        {["Popular", "Newest"].map((option) => (
          <span key={option}>
            <input
              id={option}
              type="radio"
              name="sort_option"
              value={option}
              defaultChecked={option === sortOption}
            />
            <label htmlFor={option}>{option}</label>
          </span>
        ))}
      </div>
    </div>
  );
};

export default CatalogHeader;
