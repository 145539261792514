import React, { useState } from "react";
import { CloseIcon, SearchIcon } from "../../../images";

const HeaderSearch = () => {
  const [toggleSearchSmallScreen, setToggleSearchSmallScreen] = useState(false);

  const toggleSearch = () => {
    setToggleSearchSmallScreen((prev) => !prev);
  };

  // TODO: Handle key down and Search

  return (
    <>
      <form
        action="#"
        className={`header__form ${
          toggleSearchSmallScreen && "header__form--active"
        }`}
      >
        <input
          className="header__form-input"
          type="text"
          placeholder="I'm looking for..."
        />
        <button className="header__form-btn" type="button">
          <SearchIcon />
        </button>
        <button
          type="button"
          className="header__form-close"
          onClick={toggleSearch}
        >
          <CloseIcon />
        </button>
      </form>

      {/* Small Screen toggler */}
      <button className="header__search" type="button" onClick={toggleSearch}>
        <SearchIcon />
      </button>
    </>
  );
};

export default HeaderSearch;
