import * as api from "../../api";
import { GET_MOVIES, GET_WATCHLIST } from "../reducers/movies/actionTypes";
import { MoviesDispatchType } from "../reducers/movies/types";

export const getMovies = () => async (dispatch: MoviesDispatchType) => {
  try {
    const { data } = await api.getMovies();
    dispatch({ type: GET_MOVIES, payload: data });
  } catch (error) {
    console.warn(error.response);
    // TODO
    // dispatch({
    //     type: GET_MOVIES_ERROR,
    //     payload: {
    //       status_code: error.response ? error.response.status : 500,
    //       message: error.response
    //         ? error.response.data.message
    //         : "Server is Inaccessible",
    //     },
    //   });
  }
};

export const getWatchList = () => async (dispatch: MoviesDispatchType) => {
  try {
    const { data } = await api.getWatchList();

    dispatch({ type: GET_WATCHLIST, payload: data });
  } catch (error) {
    console.warn(error);
  }
};

export const updateWatchList = (movieId: string) => async (
  dispatch: MoviesDispatchType
) => {
  const { data } = await api.updateWatchList(movieId);

  dispatch({ type: GET_WATCHLIST, payload: data });
  try {
  } catch (error) {
    console.warn(error);
  }
};
