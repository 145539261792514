import React, { useState } from "react";
import { RatingsIcon, ViewIcon } from "../../../images";
import ImagePreloader from "../../Loaders/PreLoaders/ImagePreloader";
import RouteLink from "../../utils/RouteLink";
import { Props } from "./types";

const SubscriptionCarouselMovieCard = ({ movie }: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div>
      <div className="card">
        <RouteLink to={`/Movie/v/${movie._id}`} className="card__cover">
          {!loaded && (
            <ImagePreloader useHeight strictWidth width={192} height={270} />
          )}
          <img
            src={movie.cover_image}
            alt={movie.title}
            onLoad={() => setLoaded(true)}
            style={loaded ? { display: "inherit" } : { display: "none" }}
          />
          <ViewIcon />
        </RouteLink>

        <span className="card__rating">
          <RatingsIcon />
          {movie.rating}
        </span>

        <h3 className="card__title card__title--subs" style={{ maxWidth: 192 }}>
          <RouteLink to={`/Movie/v/${movie._id}`}>{movie.title}</RouteLink>
        </h3>
      </div>
    </div>
  );
};

export default SubscriptionCarouselMovieCard;
