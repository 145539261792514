import { motion } from "framer-motion";
import Plyr from "plyr-react";
import React from "react";
import { variantsPageSection } from "../../utils/motionVariants";

type Props = {
  youtubeId: string;
};
const MovieTrailer = ({ youtubeId }: Props) => {
  const videoSrc: Plyr.SourceInfo = {
    type: "video",
    sources: [
      {
        src: youtubeId,
        provider: "youtube",
      },
    ],
  };
  return (
    <>
      <motion.div
        variants={variantsPageSection}
        key={`trailer player ${youtubeId}`}
        className="col-12 col-xl-8"
      >
        <Plyr source={videoSrc} />
      </motion.div>
    </>
  );
};

export default MovieTrailer;
