import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import RouteLink from "../../components/utils/RouteLink";
import setRouteStatus from "../utils/setRouteStatus";

const NotFound = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteStatus("DONE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="page-404 section--bg" data-bg="img/bg.jpg">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-404__wrap">
              <div className="page-404__content">
                <h1 className="page-404__title">404</h1>
                <p className="page-404__text">
                  The page you are looking for is not available!
                </p>
                <RouteLink to="/Home" className="page-404__btn">
                  go back
                </RouteLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
