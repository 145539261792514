// Framework | Third Party types
import { History, LocationState } from "history";
// API
import * as api from "../../api";
// Constants & Types
import {
  AUTH_ERROR,
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  AUTH_STATUS,
} from "../reducers/auth/actionTypes";
import { AuthDispatchType } from "../reducers/auth/types";

// SIGN IN ACTION
//#region
export const signIn = (
  formdata: FormData,
  history: History<LocationState>
) => async (dispatch: AuthDispatchType) => {
  dispatch({ type: AUTH_STATUS, payload: "IN PROGRESS" });
  try {
    const { data } = await api.signIn(formdata);

    dispatch({ type: AUTH_SIGN_IN, payload: data });
    dispatch({ type: AUTH_STATUS, payload: "DONE" });

    history.push("/Home");
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: {
        status_code: error.response ? error.response.status : 500,
        message: error.response
          ? error.response.data.message
          : "Server is Inaccessible",
      },
    });
    dispatch({ type: AUTH_STATUS, payload: "FAILED" });
  }
};
//#endregion

// SIGN UP ACTION
//#region
export const SignUp = (
  formData: FormData,
  history: History<LocationState>
) => async (dispatch: AuthDispatchType) => {
  dispatch({ type: AUTH_STATUS, payload: "IN PROGRESS" });

  try {
    const { data } = await api.signUp(formData);
    dispatch({ type: AUTH_SIGN_IN, payload: data });
    dispatch({ type: AUTH_STATUS, payload: "DONE" });
    history.push("/Home");
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: {
        status_code: error.response ? error.response.status : 500,
        message: error.response
          ? error.response.data.message
          : "Server is Inaccessible",
      },
    });
    dispatch({ type: AUTH_STATUS, payload: "FAILED" });
  }
};
//#endregion

export const signOut = (history: History<LocationState>) => (
  dispatch: AuthDispatchType
) => {
  dispatch({ type: AUTH_SIGN_OUT });
  dispatch({ type: AUTH_STATUS, payload: "IDLE" });
  history.push("/");
};
