import React from "react";
import OriginalsMovieCarousel from "../../../components/Carousels/OriginalsCarousel";

const OriginalsSection = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="section__title">
              Watch<b>TV</b> Originals
            </h2>
            <p className="section__text">
              Get right into Top Grossing originals
            </p>
          </div>
          {/*  */}
        </div>
      </div>
      <OriginalsMovieCarousel />
    </>
  );
};

export default React.memo(OriginalsSection);
