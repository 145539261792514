import styled from "styled-components";
const OriginalsCarouselLoader = () => {
  return (
    <div>
      <CarouselLoader>
        <div className="carousel--loader__item" />
        <div className="carousel--loader__item" />
        <div className="carousel--loader__item" />
      </CarouselLoader>
    </div>
  );
};

export default OriginalsCarouselLoader;

const CarouselLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  & .carousel--loader__item {
    margin: 20px;
    border-radius: 1.5rem;
    height: 330px;
    width: 414px !important;
    animation: pulseAnim 1s infinite ease-in-out;

    @media (max-width: 1200px) {
      &:last-child {
        display: none;
      }
    }
    @media (max-width: 768px) {
      &:first-child {
        display: none;
      }
    }
  }
`;
